const pathSegments = window.location.origin;

interface ApiConfigInterface {
  REACT_APP_ENV: string;
  REACT_APP_API_URL: string;
  REACT_APP_DOMAIN: string;
  REACT_APP_POOLDATA_USERPOOLID: string;
  REACT_APP_POOLDATA_CLIENTID: string;
  REACT_APP_GOOGLE_CLIENT_ID: string;
  REACT_APP_MICROSOFT_CLIENT_ID: string;
  REACT_APP_GITHUB_CLIENT_ID: string;
  REACT_APP_COGNITO_URL: string;
  REACT_APP_MICROSOFT_AUTHORITY_URL: string;
}

const res = (): string => {
  switch (pathSegments) {
    case "http://localhost:3000":
      return "dev";
    case "https://www-test.dev.utstamp.com":
      return "dev";
    case "https://www.dev.utstamp.com":
      return "test";
    case "https://www-test.utstamp.com":
      return "staging";
    case "https://www.utstamp.com":
      return "prod";
    default:
      return "dev";
  }
};

export const ApiConfig = (): ApiConfigInterface => {
  switch (res()) {
    case "dev":
      return {
        REACT_APP_ENV: "dev",
        REACT_APP_API_URL: "https://api-test.dev.utstamp.com",
        REACT_APP_DOMAIN: "https://www-test.dev.utstamp.com",
        REACT_APP_POOLDATA_USERPOOLID: "us-west-2_uGvjqQvFk",
        REACT_APP_POOLDATA_CLIENTID: "1js4hbdt8cudhqcd8av8oij8qq",
        REACT_APP_GOOGLE_CLIENT_ID:
          "1030981820556-pgegm6k49feb0qn3od22sij1930nepk3.apps.googleusercontent.com",
        REACT_APP_MICROSOFT_CLIENT_ID: "9a00a399-0c49-48f5-8d01-96e992fa4684",
        REACT_APP_GITHUB_CLIENT_ID: "05b29aebd7bb74f5828d",
        REACT_APP_COGNITO_URL:
          "https://utstamp-auth.auth.us-west-2.amazoncognito.com",
        REACT_APP_MICROSOFT_AUTHORITY_URL:
          "https://login.microsoftonline.com/common",
      };
    case "test":
      return {
        REACT_APP_ENV: "test",
        REACT_APP_API_URL: "https://api.dev.utstamp.com",
        REACT_APP_DOMAIN: "https://www.dev.utstamp.com",
        REACT_APP_POOLDATA_USERPOOLID: "us-west-2_uGvjqQvFk",
        REACT_APP_POOLDATA_CLIENTID: "1js4hbdt8cudhqcd8av8oij8qq",
        REACT_APP_GOOGLE_CLIENT_ID:
          "1030981820556-pgegm6k49feb0qn3od22sij1930nepk3.apps.googleusercontent.com",
        REACT_APP_MICROSOFT_CLIENT_ID: "9a00a399-0c49-48f5-8d01-96e992fa4684",
        REACT_APP_GITHUB_CLIENT_ID: "05b29aebd7bb74f5828d",
        REACT_APP_COGNITO_URL:
          "https://utstamp-auth.auth.us-west-2.amazoncognito.com",
        REACT_APP_MICROSOFT_AUTHORITY_URL:
          "https://login.microsoftonline.com/common",
      };
    case "staging":
      return {
        REACT_APP_ENV: "staging",
        REACT_APP_API_URL: "https://api-test.utstamp.com",
        REACT_APP_DOMAIN: "https://www-test.utstamp.com",
        REACT_APP_POOLDATA_USERPOOLID: "us-west-2_s73LR3ULM",
        REACT_APP_POOLDATA_CLIENTID: "2ke64aj3e4uchigflomav49lb7",
        REACT_APP_GOOGLE_CLIENT_ID:
          "138574876018-9jmp5i3tabgms06pejgeqo7781mgn8fk.apps.googleusercontent.com",
        REACT_APP_MICROSOFT_CLIENT_ID: "eb298ad3-e9fe-44f4-a241-cc8051eee482",
        REACT_APP_GITHUB_CLIENT_ID: "05b29aebd7bb74f5828d",
        REACT_APP_COGNITO_URL:
          "https://ut-stamp.auth.us-west-2.amazoncognito.com",
        REACT_APP_MICROSOFT_AUTHORITY_URL:
          "https://login.microsoftonline.com/common",
      };
    case "prod":
      return {
        REACT_APP_ENV: "prod",
        REACT_APP_API_URL: "https://api.utstamp.com",
        REACT_APP_DOMAIN: "https://www.utstamp.com",
        REACT_APP_POOLDATA_USERPOOLID: "us-west-2_s73LR3ULM",
        REACT_APP_POOLDATA_CLIENTID: "2ke64aj3e4uchigflomav49lb7",
        REACT_APP_GOOGLE_CLIENT_ID:
          "138574876018-9jmp5i3tabgms06pejgeqo7781mgn8fk.apps.googleusercontent.com",
        REACT_APP_MICROSOFT_CLIENT_ID: "eb298ad3-e9fe-44f4-a241-cc8051eee482",
        REACT_APP_GITHUB_CLIENT_ID: "05b29aebd7bb74f5828d",
        REACT_APP_COGNITO_URL:
          "https://ut-stamp.auth.us-west-2.amazoncognito.com",
        REACT_APP_MICROSOFT_AUTHORITY_URL:
          "https://login.microsoftonline.com/common",
      };
    default:
      return {
        REACT_APP_ENV: "dev",
        REACT_APP_API_URL: "https://api-test.dev.utstamp.com",
        REACT_APP_DOMAIN: "https://www-test.dev.utstamp.com",
        REACT_APP_POOLDATA_USERPOOLID: "us-west-2_uGvjqQvFk",
        REACT_APP_POOLDATA_CLIENTID: "1js4hbdt8cudhqcd8av8oij8qq",
        REACT_APP_GOOGLE_CLIENT_ID:
          "1030981820556-pgegm6k49feb0qn3od22sij1930nepk3.apps.googleusercontent.com",
        REACT_APP_MICROSOFT_CLIENT_ID: "9a00a399-0c49-48f5-8d01-96e992fa4684",
        REACT_APP_GITHUB_CLIENT_ID: "05b29aebd7bb74f5828d",
        REACT_APP_COGNITO_URL:
          "https://utstamp-auth.auth.us-west-2.amazoncognito.com",
        REACT_APP_MICROSOFT_AUTHORITY_URL:
          "https://login.microsoftonline.com/common",
      };
  }
};

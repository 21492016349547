import { getBackendVersion } from "./version.service";
import { Box, CircularProgress } from "@mui/material";
import { type FC, useState, useEffect, useCallback } from "react";
import { isNotEmptyString } from "../../utils/utils";
import { ApiConfig } from "../../utils/apiConfig";

const Version: FC = () => {
  const DOMAIN = `${ApiConfig().REACT_APP_DOMAIN}`;
  const [backendVersion, setBackendVersion] = useState<string>();
  const [frontendVersion, setFrontendVersion] = useState<string>();
  const [errorReasonBackend, setErrorReasonBackend] = useState("");
  const [errorReasonFrontend, setErrorReasonFrontend] = useState("");
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const queryFrontendVersionData = useCallback((): void => {
    setLoading1(true);
    setErrorReasonFrontend("");
    fetch(`${DOMAIN}/front-version.txt`)
      .then(async (response) => await response.text())
      .then((text) => {
        setFrontendVersion(text);
      })
      .catch((error) => {
        setLoading1(false);
        setErrorReasonFrontend("Can not get frontend version");
      });
    setLoading1(false);
  }, [DOMAIN]);

  const queryBackendVersionData = useCallback(async (): Promise<void> => {
    setLoading2(true);
    try {
      setErrorReasonBackend("");
      const res = await getBackendVersion();
      if (res) {
        setErrorReasonBackend("");
        setBackendVersion(res);
      } else {
        setErrorReasonBackend("Can not get backendVersion");
      }
    } catch (error) {
      setLoading2(false);
      setErrorReasonBackend("Can not get backendVersion");
    }
    setLoading2(false);
  },[]);

  useEffect(() => {
    queryFrontendVersionData();
    queryBackendVersionData();
  }, [queryFrontendVersionData, queryBackendVersionData]);

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {loading1 || loading2 ? (
        <CircularProgress />
      ) : (
        <Box sx={{ textAlign: "left" }}>
          <Box
            sx={{
              fontSize: "30px",
              fontFamily: "Poppins",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            Version:&nbsp;&nbsp;
            <Box
              sx={{ fontSize: "24px", fontFamily: "Poppins", fontWeight: 600 }}
            >
              {frontendVersion ?? "unknown"}
            </Box>
          </Box>

          <Box
            sx={{
              fontSize: "30px",
              fontFamily: "Poppins",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            Endpoint:&nbsp;&nbsp;
            <Box
              sx={{ fontSize: "24px", fontFamily: "Poppins", fontWeight: 600 }}
            >
              {backendVersion ?? "unknown"}
            </Box>
          </Box>
        </Box>
      )}
      {isNotEmptyString(errorReasonFrontend) && (
        <Box
          sx={{
            fontSize: "14px",
            color: "red",
            fontFamily: "Poppins",
            marginTop: "30px",
          }}
        >
          {frontendVersion}
        </Box>
      )}
      {isNotEmptyString(errorReasonBackend) && (
        <Box
          sx={{
            fontSize: "14px",
            color: "red",
            fontFamily: "Poppins",
            marginTop: "30px",
          }}
        >
          {errorReasonBackend}
        </Box>
      )}
    </Box>
  );
};

export default Version;

import { Button } from "@mui/material";

interface bth {
  onClick: () => void;
  text: string;
  option?: {
    background?: string;
    color?: string;
    margin?: string;
  };
}
const CancelButton = (props: bth) => {
  const { onClick, text, option } = props;
  return (
    <>
      <Button
        onClick={() => onClick()}
        sx={{
          width: "120px",
          height: "44px",
          background:
            option?.background ||
            "linear-gradient(90deg, #1CB5E5 0%, #2884E7 100%)",
          boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.3)",
          borderRadius: "30px !important",
          color: option?.color || "#FFFFFF !important",
          margin: option?.margin || "0 30px",
          border: "none",
          textTransform: "none",
          fontSize: "16px",
        }}
      >
        {text}
      </Button>
    </>
  );
};
export default CancelButton;

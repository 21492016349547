import { useState } from "react";
import { Box, Theme, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  tipText: string;
  type: "text" | "password" | "number";
  handelValue: (value: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  inputStyle: {
    width:"100%",
    "& .MuiOutlinedInput-root":{
        borderRadius:"8px"
    }
  },
}));

const InputBox = (props: Props) => {
  const classes = useStyles();
  const { tipText, type, handelValue } = props;
  const [value, setValue] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    handelValue(event.target.value);
  };
  return (
    <Box sx={{
        width:"100%"
    }}>
      <TextField
        size="small"
        placeholder={tipText}
        type={type}
        value={value}
        onChange={handleChange}
        className={classes.inputStyle}
      />
    </Box>
  );
};

export default InputBox;

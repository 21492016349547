import { useEffect, useState } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { storeValue } from "../../utils/utils";
import ico_microsoft from "../../images/portal/login/ico-microsoft.svg";
import ico_google from "../../images/portal/login/ico-google.svg";
// import ico_facebook from "../../images/portal/login/ico-facebook.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { ApiConfig } from "../../utils/apiConfig";

const COGNITO_UTSTAMP_CLIENT_ID = ApiConfig().REACT_APP_POOLDATA_CLIENTID;
const REDIRECT_URI = `${ApiConfig().REACT_APP_DOMAIN}/login`;
const COGNITO_URL = ApiConfig().REACT_APP_COGNITO_URL;

const useStyles = makeStyles((_theme: Theme) => ({
  loginButton: {
    width: "100% !important",
    height: "44px !important",
    display: "flex !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    borderRadius: "24px !important",
    boxShadow: "none !important",
    border: "1px solid #E6E6E6 !important",
    background: "#F9F9F9 !important",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Roboto,sans-serif",
    marginBottom: "16px",
    "& div": {
      background: "#F9F9F9 !important",
      display: "flex !important",
      justifyContent: "center !important",
      alignItems: "center !important",
    },
    "& span": {
      color: "rgba(0, 0, 0, 0.8) !important",
    },
  },
  loginText: {
    transform: "translateX(10px)",
    display: "inline-block",
    width: "150px",
  },
}));

const Loading = () => {
  return (
    <CircularProgress
      style={{
        width: "20px",
        height: "20px",
        color: "#307DCF",
      }}
    />
  );
};

interface ThirdPartyProps {
  loading: boolean;
  handleLoading: (isLoading: boolean) => void;
}

const ThirdParty = (props: ThirdPartyProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { loading, handleLoading } = props;
  const urlParams = new URLSearchParams(location.search);
  const cognitoCode = urlParams.get("code");
  const [googleLoading, setGoogleLoading] = useState(false);
  const [microsoftLoading, setMicrosoftLoading] = useState(false);
  // const [facebookLoading, setFacebookLoading] = useState(false);

  useEffect(() => {
    if (cognitoCode) {
      const getTokenUrl = `${ApiConfig().REACT_APP_COGNITO_URL}/oauth2/token`;
      const paramArray = [];
      paramArray.push("grant_type=authorization_code");
      paramArray.push("client_id=" + COGNITO_UTSTAMP_CLIENT_ID);
      paramArray.push("code=" + cognitoCode);
      paramArray.push("redirect_uri=" + REDIRECT_URI);
      const paramBody = paramArray.join("&");
      fetch(getTokenUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: paramBody,
      })
        .then((response) => {
          return response.json();
        })
        .then((res: any) => {
          storeValue("loginMethod", "thirdParty");
          storeValue("UTStamp_id_token", res.id_token);
          storeValue("UTStamp_access_token", res.access_token);
          storeValue("refresh_token", res.refresh_token);
          navigate("/");
        })
        .catch((error) => {
          console.error("get token error: " + JSON.stringify(error));
        });
    }
  }, [cognitoCode, navigate]);

  const handleLogin = (type: string) => {
    if (type === "Google") setGoogleLoading(true);
    if (type === "Microsoft") setMicrosoftLoading(true);
    // if (type === "Facebook") setFacebookLoading(true);
    handleLoading(true);
    const href = `${COGNITO_URL}/oauth2/authorize?identity_provider=${type}&redirect_uri=${REDIRECT_URI}&response_type=CODE&client_id=${COGNITO_UTSTAMP_CLIENT_ID}`;
    console.log("href: ",href)
    window.location.href = href;
  };

  return (
    <Box
      style={{
        width: "100%",
        pointerEvents:
          googleLoading || microsoftLoading || loading ? "none" : "auto",
      }}
    >
      <Box
        className={classes.loginButton}
        onClick={() => {
          handleLogin("Google");
        }}
      >
        {!googleLoading && <img src={ico_google} alt="ico_google" />}
        {googleLoading ? (
          <Loading />
        ) : (
          <span className={classes.loginText}>Login with Google</span>
        )}
      </Box>
      <Box
        className={classes.loginButton}
        onClick={() => {
          handleLogin("Microsoft");
        }}
      >
        {!microsoftLoading && <img src={ico_microsoft} alt="ico_microsoft" />}
        {microsoftLoading ? (
          <Loading />
        ) : (
          <span className={classes.loginText}>Login with Microsoft</span>
        )}
      </Box>
      {/* <Box
        className={classes.loginButton}
        onClick={() => {
          handleLogin("Facebook");
        }}
      >
        {!facebookLoading && <img src={ico_facebook} alt="ico_facebook" />}
        {facebookLoading ? (
          <Loading />
        ) : (
          <span className={classes.loginText}>Login with Facebook</span>
        )}
      </Box> */}
    </Box>
  );
};

export default ThirdParty;

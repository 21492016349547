import Axios, { AxiosInstance } from "axios";
import { getStoredValue, storeValue } from "./utils";
import jwtDecode from "jwt-decode";
import userPool from "../cognito/userpool";
import { CognitoUser } from "amazon-cognito-identity-js";
import axios from "axios";
import { clearLocalData } from "../utils/auth";
import { ApiConfig } from "../utils/apiConfig";
// 刷新 access token
export const refreshAccessToken = async (
  cognitoUser: CognitoUser
): Promise<string> =>
  await new Promise((resolve, reject) => {
    cognitoUser.getSession((error: any, session: any) => {
      if (error) {
        reject(error);
        return;
      }
      if (session) {
        cognitoUser.refreshSession(
          session.getRefreshToken(),
          (error, session) => {
            if (error) {
              if (error.code === "NotAuthorizedException") {
                console.log("refresh token expired, need to login again");
                return;
              }
              reject(error);
              return;
            }
            const accessToken = session.getAccessToken().getJwtToken();
            resolve(accessToken);
          }
        );
      } else {
        const accessToken: string = session.getAccessToken().getJwtToken();
        resolve(accessToken);
      }
    });
  });

export const axiosApi = (): AxiosInstance => {
  const instance = Axios.create({
    baseURL: ApiConfig().REACT_APP_API_URL,
    headers: {
      Authorization: `${getStoredValue("UTStamp_access_token")}` || "",
    },
  });
  const loginMethod = getStoredValue("loginMethod");
  // 添加请求拦截器
  instance.interceptors.request.use(
    async (config: any) => {
      const storedAccessToken = getStoredValue("UTStamp_access_token") || "";
      if (storedAccessToken === "") {
        clearLocalData();
        window.location.href = "/";
        return config;
      }
      const decodedAccessToken: { exp: number } = jwtDecode(storedAccessToken);
      const currentTime = Date.now() / 1000;
      if (loginMethod === "email") {
        if (decodedAccessToken.exp < currentTime) {
          const cognitoUser = userPool.getCurrentUser();
          if (cognitoUser) {
            try {
              const newAccessToken = await refreshAccessToken(cognitoUser);
              storeValue("UTStamp_access_token", newAccessToken);
              config.headers.Authorization = newAccessToken;
              return await instance(config);
            } catch (e: unknown) {
              console.error("refreshAccessToken error", e);
              clearLocalData();
              window.location.href = "/";
              return config;
            }
          }
        }
        return config;
      }
      if (loginMethod === "thirdParty") {
        if (decodedAccessToken.exp > currentTime) {
          config.headers.Authorization = `${storedAccessToken}`;
          return config;
        }
        const refreshToken = getStoredValue("refresh_token") || "";
        if (refreshToken === "") {
          console.warn("refresh token is empty,please login again");
          clearLocalData();
          window.location.href = "/";
          return config;
        }
        const tokenUrl = `${ApiConfig().REACT_APP_COGNITO_URL}/oauth2/token`;
        const paramBody = `grant_type=refresh_token&client_id=${ApiConfig().REACT_APP_POOLDATA_CLIENTID}&refresh_token=${refreshToken}`;
        try {
          const response = await axios.post(tokenUrl, paramBody, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          });
          const newAccessToken = response.data.access_token;
          storeValue("UTStamp_access_token", newAccessToken);
          config.headers.Authorization = `Bearer ${newAccessToken}`;
          return await instance(config);
        } catch (e: unknown) {
          console.error("refresh_token error", e);
          clearLocalData();
          window.location.href = "/";
          return config;
        }
      }
      return config;
    },
    async (error: any) => {
      return await Promise.reject(error);
    }
  );
  return instance;
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Theme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../images/shared/Logo.svg";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../../cognito/userpool";
import LoadingButton from "@mui/lab/LoadingButton";
import { storeValue } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setUsername, setEmail } from "../../redux/actions/actions";
import ThirdParty from "./ThirdParty";
import SuperButton from "../../components/Button/SuperButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  logo: {
    margin: "30px",
    marginBottom: "0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      textAlign: "center",
    },
  },
  content: {
    width: "400px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    margin: "5vh auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 40px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto 5vh",
    },
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#000000",
    marginBottom: "15px",
  },
  loginWay: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9 !important",
    borderRadius: "24px",
    margin: "8px 0",
  },
  inputStyle: {
    width: "100%",
    height: "44px",
    margin: "8px 0 !important",
    "& .MuiInputBase-root": {
      borderRadius: "24px",
      fontSize: "14px",
    },
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "12px 14px",
    },
    "& .css-1o6z5ng": {
      padding: "12px 14px",
    },
  },
  loginBtn: {
    width: "100%",
    height: "44px",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
  password: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
  },
  backBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const username: string = urlParams.get("username")!;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [userNameValue, setUserNameValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [openVerifyEmail, setOpenVerifyEmail] = useState<boolean>(false);
  const [verifyEmail, setVerifyEmail] = useState<string>("");
  const [newPassword, setNewPassword] = useState("");
  useEffect(() => {
    if (username) {
      setUserNameValue(username);
    }
  }, [username]);
  const login = () => {
    if (!userNameValue) {
      setAlertMessage("Please enter your email");
      setIsOpen(!isOpen);
      return;
    }
    if (!passwordValue) {
      setAlertMessage("Please enter your password");
      setIsOpen(!isOpen);
      return;
    }
    setLoading(true);
    const cognitoUser = new CognitoUser({
      Username: userNameValue.trim(),
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: userNameValue.trim(),
      Password: passwordValue.trim(),
    });
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (data: any) => {
        // const userId: string = data.accessToken?.payload?.username;
        const idToken: string = data.idToken.jwtToken;
        const accessToken: string = data.accessToken.jwtToken;
        const refreshToken: string = data.refreshToken.token;
        const user_name: string = data.accessToken.payload.username;
        const email: string = data.idToken.payload.email;
        const cognito_groups = data.accessToken.payload["cognito:groups"]
          ? data.accessToken.payload["cognito:groups"]
          : undefined;
        storeValue("UTStamp_access_token", accessToken);
        storeValue("UTStamp_id_token", idToken);
        storeValue("refresh_token", refreshToken);
        storeValue("cognito_groups", JSON.stringify(cognito_groups));
        //存储到store中
        dispatch(setUsername(user_name));
        dispatch(setEmail(email));
        storeValue("loginMethod", "email");
        navigate("/");
        setLoading(false);
      },
      onFailure: (err: any) => {
        if (err.message === "User is not confirmed.") {
          navigate(`/confirm`, {
            state: {
              username: userNameValue.trim(),
              password: passwordValue.trim(),
            },
          });
        }
        setAlertMessage("Incorrect username or password.");
        setIsOpen(!isOpen);
        console.log("onFail", err.message);
        setLoading(false);
      },
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        //第一次登录需要修改密码
        console.log("newPasswordRequired", userAttributes, requiredAttributes);
        if (!userAttributes.email_verified) {
          setOpenVerifyEmail(true);
        } else {
          setOpenVerifyEmail(false);
        }
        setVerifyEmail("");
        setNewPassword("");
        setAlertMessage("newPasswordRequired.");
        setIsOpen(!isOpen);
        setOpen(true);
        setLoading(false);
      },
    });
  };
  const handleChange = () => {
    setLoading2(true);
    const cognitouser = new CognitoUser({
      Username: userNameValue,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: userNameValue,
      Password: passwordValue,
    });
    cognitouser.authenticateUser(authDetails, {
      onSuccess: (data: any) => {},
      onFailure: (err: any) => {},
      newPasswordRequired: (userAttributes: any, requiredAttributes: any) => {
        if (userAttributes.email_verified) {
          delete userAttributes.email;
        }
        delete userAttributes.email_verified;
        if (openVerifyEmail) {
          userAttributes.email = verifyEmail;
        }
        cognitouser.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: (data: any) => {
            setOpen(false);
            setAlertMessage("Change Password Successfully.");
            setIsOpen(!isOpen);
          },
          onFailure: (err: any) => {
            setAlertMessage(err);
            setIsOpen(!isOpen);
          },
        });
        setLoading2(false);
      },
    });
  };
  const handleChangeVerifyEmail = (e: any) => {
    setVerifyEmail(e.target.value);
  };
  const loginKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      login();
    }
  };
  return (
    <>
      <Box
        className={classes.root}
        style={{
          pointerEvents: loading || loading2 || loading3 ? "none" : "auto",
        }}
      >
        <Box className={classes.logo}>
          <img src={logo} alt="network error" className="logo" />
        </Box>
        <Box className="home">
          <Box className={classes.content}>
            <Box className={classes.backBox}>
              <ArrowBackIcon
                onClick={() => navigate("/")}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
              <Box className={classes.title}>Login</Box>
              <div style={{ width: "24px", height: "24px" }} />
            </Box>
            <ThirdParty
              loading={loading || loading2}
              handleLoading={() => {
                setLoading3(true);
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: "5px 0",
              }}
            >
              <div
                style={{ width: "100%", height: "1px", background: "#E6E6E6" }}
              />
              <span style={{ margin: "0 10px" }}>OR</span>
              <div
                style={{ width: "100%", height: "1px", background: "#E6E6E6" }}
              />
            </div>
            <TextField
              onKeyDown={loginKeyDown}
              size="small"
              placeholder="Username"
              type="text"
              value={userNameValue}
              onChange={(e) => setUserNameValue(e.target.value)}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={loginKeyDown}
              size="small"
              placeholder="Password"
              type="password"
              onChange={(e) => setPasswordValue(e.target.value)}
              className={classes.inputStyle}
            />
            <SuperButton
              loading={loading}
              UIType="confirm"
              value="Login"
              handleClick={login}
              option={{
                margin:"13px 0 0 0",
              }}
            />
            {/* <LoadingButton
              variant="contained"
              loading={loading}
              onClick={login}
              className={classes.loginBtn}
              sx={{
                background: loading
                  ? "#FFFFFF"
                  : "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%)",
                textTransform: "none",
                boxShadow: loading
                  ? "none"
                  : "0px 2px 5px rgba(48, 125, 207, 0.3) !important",
              }}
            >
              {!loading && "Login"}
            </LoadingButton> */}
            <p
              className={classes.password}
              style={{ cursor: "pointer", textDecorationLine: "underline" }}
              onClick={() => navigate("/forgetPassword")}
            >
              Forgot your password?
            </p>
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "#E6E6E6",
                marginTop: "30px",
              }}
            ></div>
            <p className={classes.password}>
              Don't have an account?&nbsp;
              <span
                onClick={() => navigate("/signUp")}
                style={{
                  textDecorationLine: "underline",
                  color: "#307DCF",
                  cursor: "pointer",
                }}
              >
                Sign Up
              </span>
            </p>
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={"info"}
        duration={1000}
        isOpen={isOpen}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>UTStamp</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Sign In, you should set a new password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          {openVerifyEmail && (
            <TextField
              margin="dense"
              id="verify-email"
              label="Verify Email"
              type="email"
              fullWidth
              variant="standard"
              value={verifyEmail}
              onChange={(e) => {
                handleChangeVerifyEmail(e);
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <LoadingButton onClick={handleChange} loading={loading2}>
            Change
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Login;

import * as d3 from "d3";
// import { drag } from "d3";
import { calculateMerkleTreeHashSum } from "./crypto";
import "./treeStyle.css";

const checkValue = "check✔️";
const defaultDataWidth = 90;
const defaultDataHeight = 30;

const checkColor = "#16a86c";
const submissionColor = "rgb(0,187,255)";
const pathColor = "#6b4fe8";

const isMobile = () => {
  return window.innerWidth < 768;
};

declare module "d3" {
  interface Selection<
    GElement extends d3.BaseType,
    Datum,
    PElement extends d3.BaseType,
    PDatum
  > {
    addTransform(): Selection<GElement, Datum, PElement, PDatum>;
  }
}

d3.selection.prototype.addTransform = function () {
  return this.attr(
    // "transform",
    // `translate(${defaultDataWidth / 2},${defaultDataHeight})`
    "transform",
    `scale(${isMobile() ? 0.5 : 1}) translate(-80, 20)`
  );
};

export interface ComplementPathElement {
  hash: string;
  position: "LEFT" | "RIGHT";
}

interface TreeNode {
  labelValue: number | string;
  fullHash: string | "calculating";
  left?: TreeNode;
  right?: TreeNode;
  color?: string;
  width?: number;
  height?: number;
  children?: TreeNode[];
}

// function draggable(svg: any) {
//   const move = (e: any, d: any) => {
//     const transform = d3.select(svg.node()).attr("transform");
//     const translate = transform
//       ? transform.replace(/translate\(|\)/g, "").split(",")
//       : [0, 0];
//     const x = parseFloat(translate[0].toString()) + e.dx;
//     const y = parseFloat(translate[1].toString()) + e.dy;
//     d3.select(svg.node()).attr("transform", `translate(${x},${y})`);
//   };

//   const onDrag = drag().on("drag", move);

//   svg.call(onDrag);
// }

async function buildTree(
  data: ComplementPathElement[],
  submissionHash: string,
  index = 0
): Promise<TreeNode> {
  if (index >= data.length) {
    return {
      labelValue: "submission_hash",
      fullHash: submissionHash,
      color: submissionColor,
      width: defaultDataWidth + 50,
    };
  }
  const currentNode = data[index];
  const nextIndex = index + 1;
  if (currentNode.position === "LEFT") {
    return {
      labelValue: `${checkValue}`,
      fullHash: "calculating",
      color: checkColor,
      left: {
        fullHash: currentNode.hash,
        // labelValue: currentNode.hash.slice(0, 6) + "...",
        labelValue: `path[${data.length - 1 - index}]`,
        // width: defaultDataWidth + 20,
      },
      right: await buildTree(data, submissionHash, nextIndex),
    };
  } else {
    return {
      labelValue: `${checkValue}`,
      fullHash: "calculating",
      color: checkColor,
      left: await buildTree(data, submissionHash, nextIndex),
      right: {
        fullHash: currentNode.hash,
        // labelValue: currentNode.hash.slice(0, 6) + "...",
        labelValue: `path[${data.length - 1 - index}]`,
        // width: defaultDataWidth + 20,
      },
    };
  }
}

export async function drawTree(
  path: ComplementPathElement[],
  submissionHash: string,
  markleRoot: string,
  svg: any,
  layoutWidth: number = 700,
  layoutHeight: number | undefined = undefined
): Promise<void> {
  if (path.length === 0) {
    return;
  }

  let reversedPath: ComplementPathElement[] = [];
  Object.assign(reversedPath, path);
  reversedPath.reverse();

  layoutHeight =
    layoutHeight ||
    defaultDataHeight * 3 + reversedPath.length * (defaultDataHeight * 2);

  let head = await buildTree(reversedPath, submissionHash);

  svg.attr("width", "100%").attr("height", "100%").append("g");
  svg.append("g").attr("class", "nodes").addTransform();
  svg.append("g").attr("class", "links").addTransform();

  const treeLayout = d3.tree().size([layoutWidth, layoutHeight]);
  const generateChildren = async (t: TreeNode) => {
    t.children = [];
    if (t.left) {
      t.children.push(t.left);
      await generateChildren(t.left);
    }
    if (t.right) {
      t.children.push(t.right);
      await generateChildren(t.right);
    }
    if (t.children.length === 0) {
      return;
    }
  };

  const verifyTree = async (t: TreeNode): Promise<string> => {
    if (t.children === undefined) {
      console.warn("t.children is undefined");
      return "";
    }
    if (t.children.length === 0) {
      return t.fullHash;
    }
    const leftHash = await verifyTree(t.children[0]);
    const rightHash = await verifyTree(t.children[1]);
    const hex = await calculateMerkleTreeHashSum(leftHash, rightHash);
    t.fullHash = hex.toString();
    return hex.toString();
  };
  let calculatedRoot = "";
  await Promise.all([
    generateChildren(head),
    verifyTree(head).then((r) => (calculatedRoot = r)),
  ]);

  head.labelValue = "Merkle Tree Root";
  head.width = defaultDataWidth * 1.5;
  console.assert(calculatedRoot === markleRoot, "Merkle root is not correct");

  const root: d3.HierarchyNode<any> = d3.hierarchy(head);
  treeLayout(root);
  // draggable(svg);

  var nodeToolTip = d3
    .select(".TreeViz")
    .append("div")
    .style("position", "absolute")
    .style("visibility", "hidden")
    .style("background-color", "#f1f1f1")
    .style("border", "solid")
    .style("border-width", "1px")
    .style("border-radius", "5px")
    .style("padding", "10px")
    .text("I'm a circle!");

  d3.select("svg g.nodes")
    .selectAll("rect.node")
    .data(root.descendants())
    .enter()
    .append("rect")
    .classed("node", true)
    .attr("opacity", 1.0)
    .attr("id", function (d: { data: { fullHash: any } }) {
      return d.data.fullHash;
    })
    .attr("fill", function (d: { data: { color: any } }) {
      return d.data.color || pathColor;
    })
    .attr("x", function (d: any): number {
      return d.x;
    })
    .attr("y", function (d: any): number {
      return d.y;
    })
    .attr("width", function (d: any): number {
      return d.data.width || defaultDataWidth;
    })
    .attr("height", function (d: any): number {
      return d.data.height || defaultDataHeight;
    });

  d3.select("svg g.nodes")
    .selectAll("rect.node")
    .on("mouseover", function (d: any) {
      if(isMobile()) return;
      return nodeToolTip.style("visibility", "visible");
    })
    .on("mousemove", function (d: any) {
      if (isMobile()) return;
      nodeToolTip.text(d.currentTarget.id);
      nodeToolTip.style("top", d.y - 30 + "px").style("left", d.x + 30 + "px");
    })
    .on("mouseout", function (d: any) {
      if (isMobile()) return;
      return nodeToolTip.style("visibility", "hidden");
    });

  d3.select("svg g.nodes")
    .selectAll("text")
    .data(root.descendants())
    .enter()
    .append("text")
    .attr("transform", function (d: any): string {
      return (
        "translate(" +
        (d.x + (d.data.width || defaultDataWidth) / 2) +
        ", " +
        (d.y + (d.data.height || defaultDataHeight) / 2) +
        ")"
      );
    })
    .attr("text-anchor", "middle")
    .attr("dominant-baseline", "middle")
    .attr("pointer-events", "none")
    .text(function (d: { data: TreeNode }) {
      return d.data.labelValue;
    });

  d3.select("svg g.links")
    .selectAll("line.link")
    .data(root.links())
    .enter()
    .append("line")
    .classed("link", true)
    .attr("x1", function (d: any): number {
      return d.source.x + (d.source.data.width || defaultDataWidth) / 2;
    })
    .attr("y1", function (d: any): number {
      return d.source.y + (d.source.data.height || defaultDataHeight);
    })
    .attr("x2", function (d: any): number {
      return d.target.x + (d.source.data.width || defaultDataWidth) / 2;
    })
    .attr("y2", function (d: any): number {
      return d.target.y;
    });
}

import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    width: "100%",
    height: "50px",
    color: "#AAAAAA",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "0 30px",
    boxSizing: "border-box",
    flexShrink: 0,
  },
  icon: {
    marginRight: "25px",
    color: "#fff",
  },
}));
interface MenuItemsProps {
  name: string;
  imgSrc: string;
  menuIndex: number;
  selectedIndex: number;
  onClick: () => void;
}

const MenuItems = (props: MenuItemsProps) => {
  const { name, imgSrc, menuIndex, selectedIndex } = props;
  const classes = useStyles();
  return (
    <>
      <Box
        className={classes.menuItem}
        onClick={props.onClick}
        style={{
          color: selectedIndex === menuIndex ? "#fff" : "#AAAAAA",
          background:
            selectedIndex === menuIndex
              ? "linear-gradient(90deg, #1CB5E5 -6.25%, #2884E7 111.88%)"
              : "",
        }}
      >
        <img
          src={imgSrc}
          alt="imgSrc"
          className={classes.icon}
          style={{
            filter: selectedIndex === menuIndex ? "brightness(200%)" : "none",
          }}
        />
        <span>{name}</span>
      </Box>
    </>
  );
};
export default MenuItems;

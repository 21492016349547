import * as React from "react";
import { Box, IconButton } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
interface PropsList {
  loading: boolean;
  currentPage: number;
  hasMore: boolean;
  totalCount?: number;
  rowsPerPage?: number;
  onClickPrev: () => void;
  onClickNext: () => void;
}

export const ListPagination: React.FC<PropsList> = (props) => {
  const { loading, currentPage, hasMore, onClickPrev, onClickNext } = props;
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: loading ? "not-allowed" : "pointer",
        pointerEvents: loading ? "none" : "auto",
        margin: "22px 0",
      }}
    >
      <IconButton
        onClick={onClickPrev}
        disabled={currentPage === 1}
        style={{
          fontSize: "14px",
          color: currentPage !== 1 ? "black" : "rgba(0, 0, 0, 0.54)",
        }}
      >
        <NavigateBeforeIcon
          color={currentPage !== 1 ? "secondary" : "inherit"}
          fontSize="small"
        />
        Prev
      </IconButton>
      <Box
        style={{
          width: "32px",
          height: "32px",
          color: "#fff",
          background: "linear-gradient(0deg, #1CB5E5 0%, #3993F3 100%)",
          boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.1)",
          borderRadius: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        {currentPage}
      </Box>
      <IconButton
        onClick={onClickNext}
        disabled={!hasMore}
        style={{
          fontSize: "14px",
          color: hasMore ? "black" : "rgba(0, 0, 0, 0.54)",
        }}
      >
        Next
        <NavigateNextIcon
          color={hasMore ? "secondary" : "inherit"}
          fontSize="small"
        />
      </IconButton>
    </Box>
  );
};

export default ListPagination;

import React, { useState } from 'react';
import { Box, Theme, Tabs, Tab } from "@mui/material";
import { makeStyles } from '@mui/styles'
import ChangeName from './ChangeName'
import ChangePassword from './ChangePassword'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        fontFamily: 'Avenir',
        fontStyle: "normal",
        padding: "20px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 0",
        }
    },
    header: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    item: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    tabs: {
        "& .css-1aquho2-MuiTabs-indicator": {
            backgroundColor: "black !important",
        },
        "& .css-ttwr4n":{
            backgroundColor: "black !important",
        }

    }
}))

const Profile: React.FC = () => {
    const classes = useStyles()
    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.header}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        className={classes.tabs}
                    >
                        <Tab
                            label="Profile"
                            style={{
                                textTransform: 'none',
                                color: value === 0 ? 'black' : 'grey',
                                fontSize:"20px",
                                fontWeight: value === 0 ? "bold" : "normal"
                            }}
                        />
                        <Tab label="Password"
                            style={{
                                textTransform: 'none',
                                color: value === 1 ? 'black' : 'grey',
                                fontSize: "20px",
                                fontWeight:value === 1 ? "bold" : "normal"
                            }}
                        />
                    </Tabs>
                </Box>
                <Box>
                    {value === 0 && <Box className={classes.item}><ChangeName /></Box>}
                    {value === 1 && <Box className={classes.item}><ChangePassword /></Box>}
                </Box>
            </Box>
        </>
    )
}
export default Profile

import { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import { Box, Theme, Divider } from "@mui/material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


const useStyles = makeStyles((theme: Theme) => ({
    dialogBox: {
        "& .MuiTypography-h6": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
    },
    BackIcon: {
        cursor: "pointer"
    },
}))

interface Props {
    hashValue?: string;
    textValue?: string;
    textNotes?: string;
    filename?: string;
}
const ViewContent = (props: Props) => {
    const { hashValue, textValue, textNotes, filename } = props
    const [open, setOpen] = useState<boolean>(false)
    const classes = useStyles()
    return (
        <>
            <Box style={{ display: "flex", alignItems: "center", marginTop: "30px", color: "#307DCF", }}>
                <RemoveRedEyeIcon />
                <span style={{
                    marginLeft: "10px",
                    textDecorationLine: "underline",
                    fontFamily: 'Avenir',
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "14px",
                    cursor: "pointer",
                }}
                    onClick={() => setOpen(true)}
                >View Contents</span>
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className={classes.dialogBox}
                maxWidth='lg'
            >
                <DialogTitle >
                    <span>Details</span>
                    <CloseIcon onClick={() => { setOpen(false) }} className={classes.BackIcon} />
                </DialogTitle>
                <DialogContent>
                    <Box style={{ minWidth: "50vw", minHeight: "30vh" }}>
                        <Divider />
                        {hashValue && <div>
                            <h4 style={{ margin: "20px 0 10px" }}>Content Hash for stamping</h4>
                            <span>{hashValue}</span>
                        </div>}
                        {textValue && <div>
                            <h4 style={{ margin: "20px 0 10px" }}>Content</h4>
                            <span>{textValue}</span>
                        </div>}
                        {filename && <div>
                            <h4 style={{ margin: "20px 0 10px" }}>File</h4>
                            <span>{filename}</span>
                        </div>}
                        {textNotes && <div>
                            <h4 style={{ margin: "20px 0 10px" }}>Notes</h4>
                            <span>{textNotes}</span>
                        </div>}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
export default ViewContent

import { useState, useEffect } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../../images/portal/Logo.svg";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuItems from "../menu/MenuItems";
import icon_plans from "../../../images/portal/noSelected/ico-plans.svg";
import icon_submissions from "../../../images/portal/noSelected/ico-submissions.svg";
import icon_token from "../../../images/portal/noSelected/ico-token.svg";
import icon_profile from "../../../images/portal/noSelected/ico-profile.svg";
import icon_docs from "../../../images/portal/noSelected/ico-docs.svg";
import icon_web from "../../../images/portal/noSelected/ico-web.svg";
import { ApiConfig } from "../../../utils/apiConfig";
const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    width: "280px",
    height: "100vh",
    border: "1px solid black",
    background: "#1D2329",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "33px 0",
    position: "fixed",
    zIndex: 3,
    [theme.breakpoints.down("sm")]: {
      width: "230px",
    },
  },
  logo: {
    width: "100px",
    height: "100px",
    marginBottom: "50px",
    margin: "0 auto",
    cursor: "pointer",
  },
  menuBox:{
    width:"100%",
    height:"401px",
    overflowY:"scroll",
  }
}));
interface MenuProps {
  isOpenMenu1: boolean;
}
const Menu = (props: MenuProps) => {
  const { isOpenMenu1 } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const [menuIndex, setMenuIndex] = useState<number>(3);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleDocs = () => {
    window.open(
      `${ApiConfig().REACT_APP_DOMAIN}/api/v1/index.html#utstamp`,
      "_blank"
    );
  };
  useEffect(() => {}, [isOpenMenu1]);
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  useEffect(() => {
    switch (lastSegment) {
      case "plans":
        setMenuIndex(1);
        break;
      case "submissions":
        setMenuIndex(2);
        break;
      case "token":
        setMenuIndex(3);
        break;
      case "profile":
        setMenuIndex(4);
        break;
      default:
        setMenuIndex(3);
        break;
    }
  }, [lastSegment]);
  return (
    <>
      <Box
        className={classes.menu}
        style={{
          transform:
            isOpenMenu1 && isMobile ? "translateX(-100%)" : "translateX(0)",
          transition: "transform 0.4s ease-in-out",
        }}
      >
        <img
          src={logo}
          className={classes.logo}
          alt="logo"
          onClick={() => {
            navigate("/");
          }}
        />
        <Box className={classes.menuBox}>
          <MenuItems
            name={"Tokens"}
            imgSrc={icon_token}
            menuIndex={menuIndex}
            selectedIndex={3}
            onClick={() => {
              setMenuIndex(3);
              navigate("/portal/tokens");
            }}
          />
          <MenuItems
            name={"Plans"}
            imgSrc={icon_plans}
            menuIndex={menuIndex}
            selectedIndex={1}
            onClick={() => {
              setMenuIndex(1);
              navigate("/portal/plans");
            }}
          />
          <MenuItems
            name={"My Submissions"}
            imgSrc={icon_submissions}
            menuIndex={menuIndex}
            selectedIndex={2}
            onClick={() => {
              setMenuIndex(2);
              navigate("/portal/submissions");
            }}
          />
          <div
            style={{
              width: "100%",
              height: "1px",
              background: "#333333",
              margin: "50px 0",
            }}
          ></div>
          <MenuItems
            name={"Profile"}
            imgSrc={icon_profile}
            menuIndex={menuIndex}
            selectedIndex={4}
            onClick={() => {
              setMenuIndex(4);
              navigate("/portal/profile");
            }}
          />
          <MenuItems
            name={"Docs"}
            imgSrc={icon_docs}
            menuIndex={menuIndex}
            selectedIndex={5}
            onClick={handleDocs}
          />
          <MenuItems
            name={"Back to Web"}
            imgSrc={icon_web}
            menuIndex={menuIndex}
            selectedIndex={0}
            onClick={() => {
              navigate("/");
            }}
          />
        </Box>
        
      </Box>
    </>
  );
};
export default Menu;

import React from 'react';
import { Theme, Box } from "@mui/material";
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom';
import Back from '../../../components/Back/Back'
import icon_successful from "../../../images/portal/plans/ico-successful.svg"
import ConfirmButton from '../../../components/Button/ConfirmButton';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100%",
        padding: "0 50px",
        fontFamily: 'Avenir',
        fontStyle: 'normal',
        [theme.breakpoints.down('sm')]: {
            padding: "0 20px",
        }
    },
    content: {
    },
    card: {
        width: "400px",
        height: "248px",
        margin: "0 auto",
        background: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
        borderRadius: "12px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            height: "auto",
            padding: "20px 0",
        }
    },
    title1:{
        fontWeight: '500',
        fontSize: '16px',
        marginTop:"15px"
    },
    title2:{
        fontWeight: '400',
        fontSize: '14px',
        margin:"14px 0 29px"
    }
}))

const PaySuccessful: React.FC = () => {
    const classes = useStyles()
    const urlParams = new URLSearchParams(window.location.search);
    const tokenCount: string = urlParams.get('count')!
    const navigate = useNavigate();
    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.content}>
                    <Back />
                    <Box className={classes.card}>
                        <img src={icon_successful} alt="icon_successful" />
                        <span className={classes.title1}>Payment Successful</span>
                        <span className={classes.title2}>
                            {tokenCount ==='0' ? `Your submission counts will increase ` :`Your submission counts will increase by +${tokenCount}`}
                            </span>
                        <ConfirmButton
                            text="View Token"
                            onClick={() => navigate('/portal/tokens')}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}
export default PaySuccessful

import React, { useState } from "react";
import {
  Box,
  Theme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../images/shared/Logo.svg";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserPool from "../../cognito/userpool";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import { CognitoUser } from "amazon-cognito-identity-js";
import SuperButton from "../../components/Button/SuperButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  logo: {
    margin: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      textAlign: "center",
    },
  },
  content: {
    width: "400px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    margin: "5vh auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 40px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto 5vh",
    },
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#000000",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
  loginWay: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    border: "1px solid #E6E6E6",
    borderRadius: "24px",
    margin: "8px 0",
  },
  inputStyle: {
    width: "100%",
    height: "44px",
    margin: "8px 0 !important",
    "& .MuiInputBase-root": {
      borderRadius: "24px",
      fontSize: "14px",
    },
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "12px 14px",
    },
    "& .css-1o6z5ng": {
      padding: "12px 14px",
    },
  },
  signUpBtn: {
    width: "100%",
    height: "44px",
    background: "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%) !important",
    boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.3) !important",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
  signUpInfo: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "center",
  },
  backBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  confirmBtn: {
    width: "100%",
    height: "44px",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
}));
const ForgetPassword: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [userNameValue, setUserNameValue] = useState<string>('')
  const [username, setUsername] = React.useState("");
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [verificationCode, setVerificationCode] = useState<any>();
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [open, setOpen] = React.useState(true);

  const onSubmit = async () => {
    if (
      verificationCode === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      setAlertMessage("Please input all fields");
      setIsOpen(!isOpen);
      setSeverity("info");
      return;
    }
    if (newPassword !== confirmPassword) {
      setAlertMessage("Password does not match");
      setIsOpen(!isOpen);
      setSeverity("info");
      return;
    }
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    setLoading2(true);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: (result) => {
        setAlertMessage("Password changed successfully");
        setIsOpen(!isOpen);
        setSeverity("success");
        setLoading2(false);
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      },
      onFailure: (err) => {
        setAlertMessage(err.message);
        setIsOpen(!isOpen);
        setSeverity("error");
        setLoading2(false);
      },
    });
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/login");
  };
  const confirmKeyDown = (e: any) => {
    if (e.key === "Enter") {
      onSubmit();
    }
  };
  const sendCodeKeyDown = (e: any) => {
    if (e.key === "Enter") {
      handleChange();
    }
  };
  const handleChange = async () => {
    if (username === "") {
      setAlertMessage("Please input your username");
      setIsOpen(!isOpen);
      setSeverity("info");
      return;
    }
    setLoading(true);
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        setLoading(false);
      },
      onFailure: (err) => {
        setLoading(false);
      },
      inputVerificationCode: (data) => {
        setOpen(false);
        setLoading(false);
      },
    });
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Utstamp</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To Forget Password, you should input your username.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            onKeyDown={sendCodeKeyDown}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <SuperButton
            loading={false}
            UIType="cancel"
            value="Cancel"
            handleClick={handleClose}
            option={{
              margin: "0 15px 0 0",
              width: "120px",
            }}
          />
          <SuperButton
            loading={loading}
            UIType="confirm"
            value="Change"
            handleClick={handleChange}
            option={{
              width: "120px",
            }}
          />
        </DialogActions>
      </Dialog>
      <Box className={classes.root}>
        <Box className={classes.logo}>
          <img src={logo} alt="network error" />
        </Box>
        <Box className="home">
          <Box className={classes.content}>
            <Box className={classes.backBox}>
              <ArrowBackIcon
                onClick={() => navigate("/login")}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
              <Box className={classes.title}>Change Password</Box>
              <div style={{ width: "24px", height: "24px" }} />
            </Box>

            <TextField
              onKeyDown={confirmKeyDown}
              size="small"
              type="text"
              placeholder="Confirmation code"
              onChange={(e) => setVerificationCode(e.target.value.trim())}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={confirmKeyDown}
              size="small"
              type="password"
              placeholder="New password"
              onChange={(e) => setNewPassword(e.target.value.trim())}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={confirmKeyDown}
              size="small"
              type="password"
              placeholder="Confirm new password"
              onChange={(e) => setConfirmPassword(e.target.value.trim())}
              className={classes.inputStyle}
            />
            <SuperButton
              loading={loading2}
              UIType="confirm"
              value="Confirm"
              handleClick={onSubmit}
              option={{
                margin: "13px 0 0 0",
              }}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={1000}
        isOpen={isOpen}
      />
    </>
  );
};
export default ForgetPassword;

import { useState, useEffect, useCallback } from "react";
import { Box, Theme, Divider, TextField, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { getSecretPhraseURL, getTimestampURL } from "./js/Home.service";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import copy from "copy-to-clipboard";
import * as bip39 from "bip39";
import "./css/loop.css";
import QRCode from "qrcode.react";
import SuperButton from "../../components/Button/SuperButton";
const useStyles = makeStyles((theme: Theme) => ({
  BackIcon: {
    cursor: "pointer",
  },
  dialogBox: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .css-ypiqx9-MuiDialogContent-root": {
      overflowY: "hidden",
    },
    "& .css-1ty026z": {
      overflowY: "hidden",
    },
    "& .css-1fu2e3p-MuiPaper-root-MuiDialog-paper": {
      [theme.breakpoints.down("sm")]: {
        margin: "10px",
        width: "100%",
      },
    },
    "& .css-18i3v7t": {
      [theme.breakpoints.down("sm")]: {
        margin: "10px",
        width: "100%",
      },
    },
  },
  search: {
    marginTop: "20px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
  },
  searchItem: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#307DCF",
    cursor: "pointer",
    border: "1px solid #1DB5E5",
    height: "44px",
    borderRadius: "30px",
    width: "110px",
    textAlign: "center",
    lineHeight: "44px",
  },
  searchBox: {
    padding: "37px 35px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  searchBox1: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  searchBox2: {
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px",
    },
  },
  type: {
    display: "flex",
    flexDirection: "column",
    marginRight: "24px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
      marginBottom: "10px",
    },
  },
  hash: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      // marginBottom: "14px"
    },
  },
  typeInput: {
    width: "178px",
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select":
      {
        padding: "10.5px 14px",
      },
    "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
      padding: "10.5px 14px",
    },
    "& .css-3xzbvm-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-w878ib": {
      borderRadius: "12px",
    },
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "14px",
    marginBottom: "14px",
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
      marginBottom: "10px",
    },
  },
  btn: {
    width: "86px",
    height: "43px",
    color: "#FFFFFF",
    background: "linear-gradient(90deg, #1CB5E5 0 %, #2884E7 105 %)",
    boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.3)",
    borderRadius: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "16px",
  },
  hashInput: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "14px !important",
      width: "100%",
    },
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "& .css-3xzbvm-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-1x5jdmq": {
      padding: "10.5px 14px",
    },
    "& .css-w878ib": {
      borderRadius: "12px",
    },
  },
  urlBox: {
    display: "flex",
    alignItems: "center",
    marginTop: "14px",
  },
  urlInput: {
    width: "612px",
    height: "43px",
    border: "1px solid #E5E5E5",
    borderRadius: "12px",
    padding: "0 14px",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "16px",
    color: "#307DCF",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: "40px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  copyBox: {
    width: "43px",
    height: "43px",
    border: "1px solid #E5E5E5",
    marginLeft: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "12px",
    flexShrink: 0,
    [theme.breakpoints.down("sm")]: {
      width: "43px",
    },
    "&:hover": {
      background: "#E5E5E5",
    },
  },
  languageBox: {},
  PassphraseBox: {
    marginTop: "32px",
  },
  languageInput: {
    width: "200px",
    "& .css-3xzbvm-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
      {
        padding: "10.5px 14px",
      },
    "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
      padding: "10.5px 14px",
    },
    "& .css-w878ib": {
      borderRadius: "12px",
    },
  },
  passphraseInput: {
    width: "800px",
    height: "auto",
    border: "1px solid #E6E6E6",
    borderRadius: "12px",
    padding: "10px 14px",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#307DCF",
    textAlign: "center",
    lineHeight: "22px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  typeBox: {},
  searchBox3: {},
  hashBox: {
    display: "flex",
    alignItems: "flex-end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      // alignItems: "flex-start",
    },
  },
}));
const Search = (props: any) => {
  const { stampId, qrCodeColor = "#77B1C0" } = props;
  const classes = useStyles();
  const regexExp = /^[a-f0-9]{32}$/gi;
  // const isLogIn = getStoredAccessToken()
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [openSecret, setOpenSecret] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>("Hash");
  const [hashValue, setHashValue] = useState<string>("");
  const [urlValue, setUrlValue] = useState<string>("");
  const [typeIndex, setTypeIndex] = useState<number>(1);
  const [passphrase, setPassphrase] = useState<string>("");
  const [passphraseLoading, setPassphraseLoading] = useState<boolean>(false);
  const [selectedLanguageOrder, setSelectedLanguageOrder] =
    useState<string>("english");
  const [selectedLanguageReverseOrder, setSelectedLanguageReverseOrder] =
    useState<string>("English");
  const [secretPhrase, setSecretPhrase] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  //密语转换
  const handleQueryWords = useCallback(
    (language: string) => {
      const mnemonic = bip39.entropyToMnemonic(
        stampId,
        bip39.wordlists[language]
      );
      setSecretPhrase(mnemonic);
    },
    [stampId]
  );
  useEffect(() => {
    if (stampId !== "") {
      handleQueryWords(selectedLanguageOrder);
    }
  }, [stampId, selectedLanguageOrder, handleQueryWords]);
  //弹窗1，切换类型
  const handleTypeChange = (event: any) => {
    setUrlValue("");
    const index = event.target.value === "Hash" ? 1 : 2;
    setTypeIndex(index);
    setSelectedType(event.target.value);
  };
  //查hash
  const handleQuery = async () => {
    setUrlValue("");
    setLoading(true);
    if (hashValue === "") {
      setLoading(false);
      setAlertMessage("Please enter a hash value");
      setIsOpen(!isOpen);
      return;
    }
    const isHash = regexExp.test(hashValue);
    if (!isHash) {
      setAlertMessage("Please enter a valid hash value");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    try {
      const res = await getTimestampURL(hashValue);
      if (!res.data.stamp) {
        setLoading(false);
        setAlertMessage(res.data);
        setIsOpen(!isOpen);
        return;
      }
      setUrlValue(res.data.stamp);
      console.log("res", res.data.stamp);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      setAlertMessage(e.data || "Something went wrong");
      setIsOpen(!isOpen);
    }
  };
  //查密码
  const handleSecretPhrase = async (language: string, words: string) => {
    setUrlValue("");
    setPassphraseLoading(true);
    try {
      const res = await getSecretPhraseURL(language, words);
      if (res.status === 400) {
        setAlertMessage(res.data);
        setIsOpen(!isOpen);
        setPassphraseLoading(false);
        return;
      }
      if (res.data) {
        setPassphraseLoading(false);
        setUrlValue(res.data.stamp);
      } else {
        setPassphraseLoading(false);
        setAlertMessage(res.data);
        setIsOpen(!isOpen);
      }
    } catch (e: any) {
      setAlertMessage(e.data);
      setIsOpen(!isOpen);
      setPassphraseLoading(false);
    }
  };
  //查密码，点击事件
  const handleQueryPassphrase = async (selectedLanguageValue: string) => {
    if (passphrase === "") {
      setAlertMessage("Please input words");
      setIsOpen(!isOpen);
      return;
    }
    handleSecretPhrase(selectedLanguageValue, passphrase);
  };
  //查密码，切换语言
  const handleLanguageChangeReverseOrder = (event: any) => {
    setSelectedLanguageReverseOrder(event.target.value);
  };
  //复制
  const goCopy = (value: any) => {
    if (copy(value)) {
      setAlertMessage("copy successfully");
      setSeverity("success");
      setIsOpen(!isOpen);
    } else {
      setAlertMessage(
        "Copy failed, please manually select and right-click to copy"
      );
      setSeverity("warning");
      setIsOpen(!isOpen);
    }
  };

  //弹窗2，切换语言
  const handleLanguageChangeOrder = (event: any) => {
    setSelectedLanguageOrder(event.target.value);
    handleQueryWords(event.target.value);
  };

  return (
    <>
      <Box className={classes.search}>
        <SuperButton
          loading={false}
          value="Search"
          handleClick={() => setOpenSearch(true)}
          UIType="cancel"
          option={{
            width: "110px",
            height: "44px",
            margin: "0 20px 0 0",
          }}
        />
        <SuperButton
          loading={false}
          value="Secret Phrase"
          handleClick={() => setOpenSecret(true)}
          UIType="cancel"
          option={{
            width: "130px",
            height: "44px",
            margin: "0 20px 0 0",
          }}
        />
      </Box>
      <Dialog
        open={openSearch}
        onClose={() => setOpenSearch(false)}
        className={classes.dialogBox}
        maxWidth="lg"
      >
        <DialogTitle>
          <span>Search</span>
          <CloseIcon
            onClick={() => {
              setOpenSearch(false);
            }}
            className={classes.BackIcon}
          />
        </DialogTitle>
        <DialogContent>
          <Divider />
          <Box className={classes.searchBox}>
            <Box className={classes.searchBox1}>
              <Box className={classes.type}>
                <span className={classes.title}>Type:</span>
                <TextField
                  select
                  value={selectedType}
                  onChange={handleTypeChange}
                  className={classes.typeInput}
                >
                  <MenuItem value="Hash">Hash</MenuItem>
                  <MenuItem value="Passphrase">Passphrase</MenuItem>
                </TextField>
              </Box>
              {typeIndex === 1 && (
                <Box className={classes.hashBox}>
                  <Box className={classes.hash}>
                    <span className={classes.title}>Hash:</span>
                    <TextField
                      value={hashValue}
                      className={classes.hashInput}
                      onChange={(e) => {
                        setHashValue(e.target.value);
                      }}
                    />
                  </Box>
                  <SuperButton
                    loading={loading}
                    value="Query"
                    handleClick={handleQuery}
                    UIType="confirm"
                    option={{
                      width: "120px",
                      height: "44px",
                      margin: "0 0 0 16px",
                    }}
                  />
                </Box>
              )}
              {typeIndex === 2 && (
                <Box>
                  <Box className={classes.hash}>
                    <Box className={classes.type}>
                      <span className={classes.title}>Language:</span>
                      <TextField
                        select
                        value={selectedLanguageReverseOrder}
                        onChange={handleLanguageChangeReverseOrder}
                        className={classes.languageInput}
                      >
                        <MenuItem value="English">English</MenuItem>
                        <MenuItem value="ChineseSimplified">简体中文</MenuItem>
                        <MenuItem value="ChineseTraditional">繁體中文</MenuItem>
                        <MenuItem value="Japanese">日本語</MenuItem>
                        <MenuItem value="Korean">Korean</MenuItem>
                        <MenuItem value="French">French</MenuItem>
                        <MenuItem value="Italian">Italian</MenuItem>
                        <MenuItem value="Spanish">Spanish</MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {typeIndex === 2 && (
              <Box className={classes.searchBox2}>
                <Box className={classes.hashBox}>
                  <Box className={classes.hash}>
                    <span className={classes.title}>
                      Passphrase (mnemonic):
                    </span>
                    <TextField
                      value={passphrase}
                      className={classes.hashInput}
                      onChange={(e) => {
                        setPassphrase(e.target.value);
                      }}
                    ></TextField>
                  </Box>
                  <Box>
                    <SuperButton
                      loading={passphraseLoading}
                      value="Query"
                      handleClick={() =>
                        handleQueryPassphrase(selectedLanguageReverseOrder)
                      }
                      UIType="confirm"
                      option={{
                        width: "120px",
                        height: "44px",
                        margin: "0 0 0 16px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
            <Box className={classes.searchBox2}>
              <span className={classes.title}>Utstamp:</span>
              <Box className={classes.urlBox}>
                <Box className={classes.urlInput}>{urlValue}</Box>
                <Box
                  className={classes.copyBox}
                  onClick={() => goCopy(urlValue)}
                >
                  <ContentCopyIcon />
                </Box>
              </Box>
            </Box>
            {urlValue && (
              <Box className="qrcode">
                <QRCode
                  value={urlValue}
                  style={{
                    marginTop: "10px",
                    width: "100px",
                    height: "100px",
                  }}
                  fgColor={qrCodeColor}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSecret}
        onClose={() => setOpenSecret(false)}
        className={classes.dialogBox}
        maxWidth="lg"
      >
        <DialogTitle>
          <span>Secret Phrase</span>
          <CloseIcon
            onClick={() => {
              setOpenSecret(false);
            }}
            className={classes.BackIcon}
          />
        </DialogTitle>
        <DialogContent>
          <Divider />
          <Box className={classes.searchBox}>
            <Box className={classes.searchBox1}>
              <Box className={classes.type}>
                <span className={classes.title}>Language:</span>
                <TextField
                  select
                  value={selectedLanguageOrder}
                  onChange={handleLanguageChangeOrder}
                  className={classes.languageInput}
                >
                  <MenuItem value="english">English</MenuItem>
                  <MenuItem value="chinese_simplified">简体中文</MenuItem>
                  <MenuItem value="chinese_traditional">繁體中文</MenuItem>
                  <MenuItem value="japanese">日本語</MenuItem>
                  <MenuItem value="korean">Korean</MenuItem>
                  <MenuItem value="french">French</MenuItem>
                  <MenuItem value="italian">Italian</MenuItem>
                  <MenuItem value="spanish">Spanish</MenuItem>
                </TextField>
              </Box>
            </Box>
            <Box className={classes.PassphraseBox}>
              <span className={classes.title}>Passphrase (mnemonic):</span>
              <Box className={classes.urlBox}>
                <Box className={classes.passphraseInput}>
                  {secretPhrase || "loading..."}
                </Box>
                <Box
                  className={classes.copyBox}
                  onClick={() => goCopy(secretPhrase)}
                >
                  <ContentCopyIcon />
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={1000}
        isOpen={isOpen}
      />
    </>
  );
};
export default Search;

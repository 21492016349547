import { Box, Theme } from "@mui/material";
import { makeStyles } from '@mui/styles'
import './css/introduce.css'
import { ApiConfig } from "../../utils/apiConfig";
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: "54px",
        [theme.breakpoints.down('sm')]: {
            // padding: "0 10px"
            marginTop: "20px",
        }
    },
    content: {
        display: "flex",
        justifyContent: "space-between",
        background: "#FFFFFF",
        borderRadius: "12px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
        padding: "10px 0",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            background: "none",
            flexDirection: "column",
            padding: "0px",
            alignItems: "center"
        }
    },
    card: {
        // width: "300px",
        borderRadius: "12px",
        padding: "17px 30px",
        background: "#FFFFFF",
        [theme.breakpoints.down('sm')]: {
            marginBottom: "10px"
        },
        "& ::-webkit-scrollbar ": {
            display: "none"
        }
    },
    title: {
        fontFamily: 'Avenir',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: '20px',
        // textAlign: "center",
        margin: "0"
    },
    textContent: {
        fontFamily: 'Avenir',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '28px',
        marginBottom: "0",
        display: 'inline-block',
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
    link: {
        display: "flex",
        alignItems: "center",
        textDecoration: "underline",
        cursor: "pointer",
        marginTop: "10px"
    },
    placeholder: {
        width: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}))

const Introduce = () => {
    const classes = useStyles()
    return (
        <Box className='introduce'>
            <Box className={classes.root}>
                <Box className={classes.content}>
                    <Box className={classes.card}>
                        <p className={classes.title}>About</p>
                        <p className={classes.textContent}>UTStamp offers a service for time stamping data in a decentralized, cryptographically verifiable way. This works by creating a unique digital fingerprint of your data, known as a hash, and storing it on the Bitcoin blockchain. The blockchain is a public, decentralized ledger that provides an immutable and tamper-proof record of transactions. By storing your data on the blockchain, UTStamp ensures that it cannot be altered or deleted without detection. This makes it a reliable way to prove the existence and integrity of your data at a specific point in time.</p>
                    </Box>
                    <Box className={classes.card}>
                        <p className={classes.title}>How to Use</p>
                        <p className={classes.textContent}>To timestamp your data with UTStamp, you can either enter the text you want to stamp or select the file you wish to timestamp. Our service will generate a hash of the data content without storing or sending the data itself to our backend server. If the data has already been stamped, we'll display the date and time of the previous timestamp, as well as a cryptographically verifiable UTStamp chain as proof of the timestamp.If you already have a SHA256 hex-encoded hash and want to submit it directly, you can use the hash tab on our website.
                        </p>
                    </Box>
                    <Box className={classes.card}>
                        <p className={classes.title}>Support</p>
                        <p className={classes.textContent} style={{
                            textAlign: 'start',
                        }}>
                            If you need to timestamp large volumes of data, you can create an account on our website and generate an API key. With the key, you can use our&nbsp;
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://github.com/DigitalWoven/utstamp-cli"
                                style={{
                                    textDecoration: "underline",
                                    color: "#307DCF",
                                    cursor: "pointer"
                                }}
                            >Open Source Line Tool</a>
                            &nbsp;
                            and
                            &nbsp;
                            <a 
                                target="_blank"
                                rel="noreferrer"
                                href={`${ApiConfig().REACT_APP_DOMAIN}/api/v1/index.html#utstamp`}
                                style={{
                                    textDecoration: "underline",
                                    color: "#307DCF",
                                    cursor: "pointer"
                                }}
                            >
                                API
                            </a>
                            &nbsp;
                            to timestamp tens of thousands of files per second quickly and easily.
                            Contact our team at
                            <a
                                href="mailto:utstamp@digitalwoven.com?Subject=Support"
                                style={{
                                    textDecoration: "none",
                                    color: "#307DCF",
                                }}
                            >&nbsp;utstamp@digitalwoven.com&nbsp;</a>for professional assistance with opening an account.</p>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default Introduce

import React, { useState } from "react";
import { Theme, Box, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SnackbarMessage from "../../../components/Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";
import { getStoredValue, axiosCognitoApi } from "../../../utils/utils";
import jwtDecode from "jwt-decode";
import "../css/profile.css";
import userPool from "../../../cognito/userpool";
import SuperButton from "../../../components/Button/SuperButton";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    fontFamily: "Avenir",
    fontStyle: "normal",
    padding: "20px",
  },
  content: {
    width: "500px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    padding: "40px 50px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 auto",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "40px 25px",
    },
  },
  title: {
    fontWeight: "800",
    fontSize: "14px",
  },
  btn: {
    display: "flex",
    marginTop: "40px",
  },
  text: {
    fontWeight: "500",
    fontSize: "14px",
    textAlign: "center",
    color: "#307DCF",
    marginTop: "10px",
    cursor: "pointer",
  },
  inputBox: {
    width: "400px",
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "& .css-3xzbvm-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-1x5jdmq": {
      padding: "10.5px 14px",
    },
    "& .css-w878ib": {
      borderRadius: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  item: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  passwordTip: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "10px",
  },
}));

const ChangePassword: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [loading, setLoading] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const handelCancel = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };
  const getNewToken = async () => {
    const storedAccessToken = getStoredValue("UTStamp_access_token") || "";
    const decodedAccessToken: { exp: number } = jwtDecode(storedAccessToken);
    const currentTime = Date.now() / 1000;
    if (decodedAccessToken.exp < currentTime) {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((error: any, session: any) => {
          if (error) {
            return;
          }
          cognitoUser.refreshSession(
            session.getRefreshToken(),
            (error, session) => {
              if (error) {
                if (error.code === "NotAuthorizedException") {
                  console.log("refresh token expired, need to login again");
                  return;
                }
                return;
              }
              const accessToken = session.getAccessToken().getJwtToken();
              return accessToken;
            }
          );
        });
      }
    } else {
      return storedAccessToken;
    }
  };
  const onSubmit = async () => {
    setLoading(true);
    if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
      setAlertMessage("Please fill in all fields");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      setAlertMessage("New password and confirm password do not match");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    const params = {
      AccessToken: await getNewToken(),
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword,
    };
    axiosCognitoApi()
      .post("", params, {
        headers: {
          "X-Amz-Target": "AWSCognitoIdentityProviderService.ChangePassword",
        },
      })
      .then(() => {
        setLoading(false);
        setAlertMessage("ChangePassword Successfully, please login again！");
        setSeverity("success");
        setIsOpen(!isOpen);
        setTimeout(() => {
          sessionStorage.clear();
          navigate("/login");
        }, 1000);
      })
      .catch((err: any) => {
        // console.log("err", err)
        setAlertMessage(err.response.data.message || "ChangePassword failed");
        setIsOpen(!isOpen);
        setLoading(false);
      });
  };
  const keyDown = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  return (
    <div className="changePassword" style={{ width: "100%" }}>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.item}>
            <p className={classes.title}>Old Password:</p>
            <TextField
              type="password"
              value={oldPassword}
              className={classes.inputBox}
              placeholder="Old Password"
              onChange={(e) => {
                setOldPassword(e.target.value.trim());
              }}
              onKeyDown={keyDown}
            ></TextField>
          </Box>
          <Box className={classes.item}>
            <p className={classes.title}>New Password:</p>
            <TextField
              type="password"
              value={newPassword}
              className={classes.inputBox}
              placeholder="New Password"
              onChange={(e) => {
                setNewPassword(e.target.value.trim());
              }}
              onKeyDown={keyDown}
            ></TextField>
          </Box>
          <Box className={classes.item}>
            <p className={classes.title}>Confirm Password:</p>
            <TextField
              type="password"
              value={confirmPassword}
              className={classes.inputBox}
              placeholder="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value.trim());
              }}
              onKeyDown={keyDown}
            ></TextField>
            <Box className={classes.passwordTip}>
              <span style={{ color: "#4799FA" }}>
                Password contains at least 8 characters, including numbers,
                letters (upper and lower case), and special symbols (such as
                @,!,%).
              </span>
            </Box>
          </Box>
          <Box className={classes.btn}>
            <SuperButton
              loading={loading}
              value="Save"
              handleClick={onSubmit}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 20px 0 0",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={handelCancel}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            {/* <LoadingButton
                            loading={loading}
                            value="Save"
                            handleClick={onSubmit}
                            option={{
                                marginLeft: "0px",
                            }}
                        />
                        <CancelButton text='Cancel' onClick={handelCancel} /> */}
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={1000}
        isOpen={isOpen}
      />
    </div>
  );
};
export default ChangePassword;

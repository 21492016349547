import React, { useEffect, useState, useCallback } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import file_icon from "../../../images/portal/submissions/file.svg";
import ListPagination from "../../../components/ListPagination/listPagination";
import { getSubmissions } from "../js/portal.service";
import { changeTime } from "../../../utils/changeTime";
import { handleQueryHash } from "../../home/js/Home.service";
import SnackbarMessage from "../../../components/Snackbar/Snackbar";
import icon_submissions from "../../../images/portal/noSelected/ico-submissions.svg";
import Skeleton from "@mui/material/Skeleton";
import { getStoredValue } from "../../../utils/utils";
import SuperButton from "../../../components/Button/SuperButton";
import SubmissionDetailDialog from "./SubmissionDetailDialog";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  dialogBox: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  BackIcon: {
    cursor: "pointer",
  },
  content: {
    width: "90%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#00000",
    margin: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px",
    },
  },
  item: {
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    marginBottom: "14px",
    display: "flex",
    alignItems: "center",
    padding: "15px 20px",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  type: {
    width: "56px",
    height: "56px",
    borderRadius: "50%",
    marginRight: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    color: "#FFFFFF",
    background: "linear-gradient(0deg, #4CC1E6 0%, #53A6FF 100%)",
    boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.1)",
    flexShrink: 0,
  },
  text: {
    width: "100%",
    overflow: "hidden",
    flex: 1,
  },
  textContent: {
    width: "100%",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    margin: "0 0 5px",
  },
  hashContent: {
    width: "100%",
    marginTop: "5px",
    marginBottom: "7px",
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  timeBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  timeText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    [theme.breakpoints.down("sm")]: {
      margin: "5px 0",
    },
  },
  noData: {
    width: "100%",
    height: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  listBox: {
    width: "100%",
    minHeight: "768px",
  },
}));
interface dataFormat {
  content?: string;
  hash: string;
  createTime: number;
  notes: string;
  expectDelay: number;
  owner: string;
  price: number;
}
const Submissions = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingQuery, setLoadingQuery] = useState<boolean>(false);
  const [queryIndex, setQueryIndex] = useState<number>();
  const [queryData, setQueryData] = useState<any>();
  const [dataList, setDataList] = useState<dataFormat[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const [startKeyArray] = React.useState(["", ""]);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const [notes, setNotes] = useState<string>("");
  const [hash, setHash] = useState<string>("");
  const [createTime, setCreateTime] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const storedAccessToken = getStoredValue("UTStamp_access_token") || "";
  const getDataList = useCallback(
    async (pageIndex: number) => {
      setLoading(true);
      setCurrentPage(pageIndex);
      const res = await getSubmissions(startKeyArray[pageIndex]);
      if (!res) {
        setHasMore(false);
        return;
      }
      const nextStartKey = res.data.nextToken;
      startKeyArray[pageIndex + 1] = nextStartKey;
      setHasMore(nextStartKey && nextStartKey !== "");
      setDataList(res.data.submissions);
      setLoading(false);
    },
    [startKeyArray]
  );
  const handlePageNext = () => {
    setQueryIndex(undefined);
    getDataList(currentPage + 1);
  };
  const handlePagePrev = () => {
    setQueryIndex(undefined);
    getDataList(currentPage - 1);
  };
  useEffect(() => {
    getDataList(1);
  }, [getDataList, storedAccessToken]);
  const handleQuery = async (
    hash: string,
    queryIndex: number,
    time: number,
    content: string,
    notes: string,
    price: number
  ) => {
    setQueryData("");
    setQueryIndex(queryIndex);
    setLoadingQuery(true);
    try {
      const response = await handleQueryHash(hash);
      if (response.status === 408) {
        setAlertMessage(response.data.message || "please check back later.");
        setIsOpen(!isOpen);
        setLoadingQuery(false);
        return;
      }
      if (response) {
        setPrice(price);
        setHash(hash);
        setCreateTime(time);
        setContent(content || "");
        setNotes(notes || "");
        setOpen(true);
        setLoadingQuery(false);
        setQueryData(JSON.stringify(response.data));
      }
    } catch (e: any) {
      setAlertMessage(e.message);
      setIsOpen(!isOpen);
      setLoadingQuery(false);
    }
  };
  return (
    <>
      <Box>
        {loading ? (
          ""
        ) : (
          <Box>
            {dataList.length === 0 ? (
              <Box className={classes.noData}>
                <img
                  src={icon_submissions}
                  alt="icon_submissions"
                  style={{ width: "150px", height: "150px" }}
                />
                <span style={{ fontSize: "20px", marginTop: "5px" }}>
                  No Submission yet.
                </span>
              </Box>
            ) : (
              ""
            )}
          </Box>
        )}
      </Box>
      <Box className={classes.content}>
        <span className={classes.title}>
          {loading ? (
            "loading..."
          ) : (
            <span>{dataList.length === 0 ? "" : "My Submissions"}</span>
          )}
        </span>
        {loading ? (
          <Box className={classes.listBox}>
            {[1, 2, 3, 4, 5, 6].map((_item, index) => (
              <Box className={classes.item} key={index}>
                <Skeleton
                  variant="circular"
                  width={56}
                  height={56}
                  style={{ marginRight: "13px" }}
                />
                <Box className={classes.text}>
                  <Skeleton
                    variant="text"
                    height={19}
                    style={{ marginBottom: "5px" }}
                  />
                  <Skeleton
                    variant="text"
                    width={130}
                    height={19}
                    style={{ marginBottom: "5px" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={114}
                    height={36}
                    style={{
                      borderRadius: "18px",
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Box className={classes.listBox}>
            {dataList.map((item, index) => (
              <Box key={index} className={classes.item}>
                <Box
                  className={classes.type}
                  style={{
                    background:
                      "linear-gradient(0deg, #4CC1E6 0%, #53A6FF 100%)",
                  }}
                >
                  <img src={file_icon} alt="file_icon" />
                </Box>
                <Box className={classes.text}>
                  <p className={classes.textContent}>Hash:&nbsp;{item.hash}</p>
                  <p className={classes.textContent}>Consumed tokens:&nbsp;{item?.price}</p>
                  <Box className={classes.timeBox}>
                    <SuperButton
                      loading={loadingQuery && queryIndex === index}
                      value="View Details"
                      handleClick={() => {
                        handleQuery(
                          item?.hash,
                          index,
                          item?.createTime,
                          item?.content ?? "",
                          item?.notes,
                          item?.price
                        );
                      }}
                      UIType="confirm"
                      option={{
                        width: "110px",
                        height: "36px",
                        boxShadow: "none",
                        fontSize: "12px",
                      }}
                    />
                    <Box className={classes.timeText}>
                      {changeTime(item.createTime)}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {dataList.length !== 0 && (
          <ListPagination
            loading={loading}
            currentPage={currentPage}
            hasMore={hasMore}
            onClickPrev={handlePagePrev}
            onClickNext={handlePageNext}
          />
        )}
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={"info"}
        duration={1000}
        isOpen={isOpen}
      />
      <SubmissionDetailDialog
        open={open}
        handleClose={() => setOpen(false)}
        info={{
          content: content,
          hash: hash,
          createTime: createTime,
          notes: notes,
          queryData: queryData,
          price: price,
        }}
      />
    </>
  );
};
export default Submissions;

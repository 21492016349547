import React, { useState } from "react";
import { Box, Theme, Avatar, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SnackbarMessage from "../../../components/Snackbar/Snackbar";
import { setUserInfo } from "../../portal/js/portal.service";
import {
  setUsername,
  setNickname,
  setAvatar,
} from "../../../redux/actions/actions";
import MyAvatarEditor from "./MyAvatarEditor";
import { useSelector, useDispatch } from "react-redux";
import "../css/profile.css";
import SuperButton from "../../../components/Button/SuperButton";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    fontFamily: "Avenir",
    fontStyle: "normal",
    padding: "20px",
  },
  content: {
    width: "500px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    padding: "40px 50px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 auto",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "40px 25px",
    },
  },
  title: {
    width: "100%",
    fontWeight: "800",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  btn: {
    display: "flex",
    marginTop: "40px",
  },
  inputBox: {
    width: "400px",
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "& .css-3xzbvm-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-1x5jdmq": {
      padding: "10.5px 14px",
    },
    "& .css-w878ib": {
      borderRadius: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  item: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0",
    },
  },
  InfoBox: {
    width: "100%",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    padding: "10.5px 14px",
    background: "#F9F9F9",
    wordBreak: "break-all",
  },
}));

const ChangeName: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const username_Store = useSelector((state: any) => state.username);
  const nickname_Store = useSelector((state: any) => state.nickname);
  const userAvatar_store = useSelector((state: any) => state.avatar);
  const email_Store = useSelector((state: any) => state.email);
  const regexExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const [avatar, setAvatar_1] = useState<string>("");
  const [nickname, setNickname_1] = useState<string>("");
  const [username, setUsername_1] = useState<string>("");
  const [email, setEmail_1] = useState<string>("");
  // const [avatarUrl, setAvatarUrl] = useState<string>('')
  const handelCancel = () => {
    setNickname_1("");
    setUsername_1("");
    setEmail_1("");
  };
  const onSubmit = async () => {
    const userInfo = {
      avatar,
      nickname: nickname.trim(),
    };
    setLoading(true);
    if (nickname === "" && username === "" && email === "" && avatar === "") {
      setAlertMessage("Please enter the information you want to change");
      setSeverity("error");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    //验证邮箱格式
    if (!regexExp.test(email) && email !== "") {
      setAlertMessage("Please enter a valid email address");
      setSeverity("error");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    const res = await setUserInfo(avatar, userInfo.nickname, username, email);
    if (!res.data) {
      setAlertMessage("Failed");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    dispatch(setAvatar(res.data.avatar));
    dispatch(setUsername(res.data.username));
    dispatch(setNickname(res.data.nickname));
    setAlertMessage("Success");
    setSeverity("success");
    setIsOpen(!isOpen);
    setLoading(false);
  };
  const keyDown = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };
  const handleBase = (base: string) => {
    setAvatar_1(base);
  };
  return (
    <div className="changeName" style={{ width: "100%" }}>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Avatar
            src={avatar || userAvatar_store}
            sx={{
              width: 72,
              height: 72,
            }}
          />
          <MyAvatarEditor handleBase={handleBase} />
          <Box className={classes.item}>
            <p className={classes.title}>{`Nickname(${nickname_Store}):`}</p>
            <TextField
              type="text"
              value={nickname}
              onKeyDown={keyDown}
              className={classes.inputBox}
              placeholder="Nickname"
              onChange={(e) => {
                setNickname_1(e.target.value);
              }}
            />
          </Box>
          <Box className={classes.item}>
            <p className={classes.title}>Username:</p>
            <Box className={classes.InfoBox}>{username_Store}</Box>
          </Box>
          {/* <TextField
                            type='text'
                            value={username}
                            onKeyDown={keyDown}
                            className={classes.inputBox}
                            placeholder='Username'
                            onChange={(e) => { setUsername_1(e.target.value.trim()) }}
                        /> */}
          <Box className={classes.item}>
            <p className={classes.title}>Email:</p>
            <Box className={classes.InfoBox}>{email_Store}</Box>
          </Box>
          {/* <TextField
                            type='text'
                            value={email}
                            onKeyDown={keyDown}
                            className={classes.inputBox}
                            placeholder='Email'
                            onChange={(e) => { setEmail_1(e.target.value.trim()) }}
                        /> */}
          <Box className={classes.btn}>
            <SuperButton
              loading={loading}
              value="Save"
              handleClick={onSubmit}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 20px 0 0",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={handelCancel}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            {/* <LoadingButton
                            loading={loading}
                            value="Save"
                            handleClick={onSubmit}
                            option={{
                                marginLeft: "0px",
                            }}
                        />
                        <CancelButton text='Cancel' onClick={handelCancel} /> */}
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={1000}
        isOpen={isOpen}
      />
    </div>
  );
};
export default ChangeName;

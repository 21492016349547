import React, { useState } from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Introduce from "./Introduce";
import QRcode from "./QRcode";
import logo from "../../images/shared/Logo.svg";
import UserInfo from "../../components/userInfo/UserInfo";
import { getStoredAccessToken } from "../../utils/localStorage";
import "./css/home.css";
import portal from "../../images/uploadFiles2/ico-portal.svg";
import bg from "../../images/uploadFiles2/background.png";
import TextArea from "./TextArea";
import FileArea from "./FileArea";
import HashArea from "./HashArea";
import ChainArea from "./ChainArea";
import SuperButton from "../../components/Button/SuperButton";
import { ApiConfig } from "../../utils/apiConfig";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    minHeight: "100vh",
    backgroundImage: `url(${bg})`,
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 30px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      margin: "8px 8px",
    },
  },
  loginButton: {
    background: "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%) !important",
    boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.3) !important",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    width: "120px",
    height: "44px",
  },
  content: {
    maxWidth: "65%",
    margin: "0 auto",
    [theme.breakpoints.down(1800)]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down(1600)]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  footer: {
    textAlign: "center",
    lineHeight: "40px",
    marginTop: "50px",
  },
  tabBox: {},
  workArea: {
    borderRadius: "12px",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      // padding: "0 10px"
    },
  },
  tabs: {
    display: "flex",
    height: "60px",
    background: "#E2E5E9",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
  },
  tab: {
    width: "25%",
    borderTopLeftRadius: "12px",
    borderTopRightRadius: "12px",
    background: "#E2E5E9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    cursor: "pointer",
  },
  inputArea: {
    display: "flex",
    padding: "18px 18px 18px 45px",
    background: "#fff",
    borderBottomLeftRadius: "12px",
    borderBottomRightRadius: "12px",
    minHeight: "420px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      height: "100%",
    },
  },
}));
const Home: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const isLogIn = getStoredAccessToken();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedValue, setSelectedValue] = useState<number>(0);
  const handleClickTab = (value: number) => {
    setSelectedValue(value);
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.title}>
        <img src={logo} alt="network error" className="logo" />
        <Box style={{ display: isLogIn ? "none" : "" }} className="loginBtn">
          <SuperButton
            UIType="confirm"
            loading={false}
            value="Login"
            handleClick={() => navigate("/login")}
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
        {isLogIn && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="loginBtn"
          >
            {!isMobile && (
              <Box
                onClick={() => navigate("/portal/tokens")}
                style={{
                  marginRight: "28px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={portal}
                  alt="portal"
                  style={{ marginRight: "10px", width: "24px", height: "24px" }}
                />
                <span style={{ fontSize: "17px" }}>Portal</span>
              </Box>
            )}
            <UserInfo />
          </Box>
        )}
      </Box>
      <Box className={classes.content}>
        <Box className="home">
          {isMobile ? <QRcode /> : ""}
          <Box className={classes.workArea}>
            <Box className={classes.tabs}>
              <Box
                className={classes.tab}
                onClick={() => handleClickTab(0)}
                style={{
                  background: selectedValue === 0 ? "#6C7D8E" : "",
                  color: selectedValue === 0 ? "#FFFFFF" : "#555555",
                }}
              >
                Text
              </Box>
              <Box
                className={classes.tab}
                onClick={() => handleClickTab(1)}
                style={{
                  background: selectedValue === 1 ? "#6C7D8E" : "",
                  color: selectedValue === 1 ? "#FFFFFF" : "#555555",
                }}
              >
                File
              </Box>
              <Box
                className={classes.tab}
                onClick={() => handleClickTab(2)}
                style={{
                  background: selectedValue === 2 ? "#6C7D8E" : "",
                  color: selectedValue === 2 ? "#FFFFFF" : "#555555",
                }}
              >
                Hash
              </Box>
              <Box
                className={classes.tab}
                onClick={() => handleClickTab(3)}
                style={{
                  background: selectedValue === 3 ? "#6C7D8E" : "",
                  color: selectedValue === 3 ? "#FFFFFF" : "#555555",
                }}
              >
                Chain
              </Box>
            </Box>
            <Box className={classes.inputArea}>
              {isMobile ? "" : <QRcode />}
              {selectedValue === 0 && <TextArea />}
              {selectedValue === 1 && <FileArea />}
              {selectedValue === 2 && <HashArea />}
              {selectedValue === 3 && <ChainArea />}
            </Box>
          </Box>
        </Box>
        <Introduce />
      </Box>
      <Box className={classes.footer}>
        <span>© 2023 DigitalWoven, Inc.</span>
        <span
          style={{
            display: "block",
          }}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={`${ApiConfig()?.REACT_APP_DOMAIN}/privacy.pdf`}
            style={{
              textDecoration: "underline",
              color: "black",
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </a>
        </span>
      </Box>
    </Box>
  );
};
export default Home;

import Home from "../pages/home/Home2";
import Login from '../pages/login/Login'
import SignUp from '../pages/signUp/SignUp'
import Confirm from '../pages/confirm/Confirm'
import ForgetPassword from "../pages/forgetPassword/ForgetPassword";
import Certificate from '../pages/home/Certificate'
import Portal from '../pages/portal/Portal'
import Version from '../pages/version/Version'
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
const theme = createTheme({
  typography: {
    fontFamily: 'Comfortaa',
  },
});
export default function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/certificate" element={<Certificate />} />
            <Route path="/portal/*" element={<Portal />} />
            <Route path="/confirm" element={<Confirm />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/version" element={<Version />} />
            <Route path="*" element={""} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

import { useState, useEffect } from 'react';
import { Box, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles'
import QRCode from 'qrcode.react';
import Prompt from '../../images/uploadFiles2/ico-prompt.svg'
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import ReactJson from "react-json-view";
import { useTheme } from '@mui/material/styles';
import Search from './Search'
import { ApiConfig } from "../../utils/apiConfig";

const useStyles = makeStyles((theme: Theme) => ({
    QRcodeBox: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "48px",
        maxWidth: "25%",
        [theme.breakpoints.down('sm')]: {
            margin: "10px 0",
            maxWidth: "100%",
            background: "#FFF",
            borderRadius: "12px",
        }
    },
    QRboxItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "240px",
        height: "240px",
        background: "#F9F9F9",
        borderRadius: "12px",
        position: "relative",
        border: "5px solid black",
        flexDirection: "column"
    },
    timeBox: {
        display: "flex",
        alignItems: "flex-start",
        marginBottom: "10px",
        [theme.breakpoints.down('sm')]: {
            margin: "10px 0",
            padding: "0 50px",
        }
    },
    timeBoxText: {
        fontFamily: 'Avenir',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        margin: "0 0 0 8px",
        display: "inline-block",
    },
    dialogBox: {
        "& .MuiTypography-h6": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        "& .css-ypiqx9-MuiDialogContent-root": {
            minWidth: "1200px",
            minHeight: "862px",
            overflowY: "hidden"
        },
        "& .css-1ty026z": {
            minWidth: "1200px",
            minHeight: "862px",
            overflowY: "hidden"
        }
    },
    BackIcon: {
        cursor: "pointer"
    }
}))
const QRcode = () => {
    const classes = useStyles()
    const colorArr = ["#79B697", "#E3A93A", "#77B1C0", "#5AB87A", "#F0913B", "#58A89E", "#1abc9c"]
    const [colorArrIndex, setColorArrIndex] = useState<number>(0)
    const [time, setTime] = useState<any>()
    const [QRdata, setQRdata] = useState<any>()
    const [QRvalue, setQRvalue] = useState("")
    const [open, setOpen] = useState(false)
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [stampId, setStampId] = useState<string>("")
    useEffect(() => {
        generateQRcode();
        setColorArrIndex(Math.floor(Math.random() * 7));
        // 计算距离下一个整点的分钟数
        const now = new Date();
        const nowMinutes = now.getMinutes();
        const nowSeconds = now.getSeconds();
        const minutesToNextHour = 10 - nowMinutes % 10;
        const secondsToNextHour = minutesToNextHour * 60 - nowSeconds;
        // 设置定时器，在下一个整点分钟数时执行一次
        const timeoutId = setTimeout(() => {
            generateQRcode();
            setColorArrIndex(Math.floor(Math.random() * 7));
            // 设置定时器，每隔10分钟执行一次
            setInterval(() => {
                generateQRcode();
                setColorArrIndex(Math.floor(Math.random() * 7));
            }, 10 * 60 * 1000);
        }, secondsToNextHour * 1000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);
    const generateQRcode = async () => {
        //获取二维码数据
        const result = await axios.get(`${ApiConfig().REACT_APP_API_URL}/timestamp`);
        const date = new Date(result.data.time);
        const hour = date.getHours();
        const minute = date.getMinutes().toString().padStart(2, '0');
        const localDateString = date.toLocaleDateString();
        const offsetInMinutes = new Date().getTimezoneOffset();
        const timezone = Math.floor(offsetInMinutes / 60)
        const timezoneOffset = timezone > 0 ? `-${timezone}` : `+${Math.abs(timezone)}`
        const localDateTimeString = `${localDateString} ${hour}:${minute}(UTC${timezoneOffset})`
        setTime(localDateTimeString)
        setQRvalue(JSON.stringify(result.data.url || result.data.stamp))
        setQRdata(JSON.stringify(result.data))
        setStampId(result.data.stamp_id)
    }
    return (
        <>
            <Box className={classes.QRcodeBox}>
                <Box className={classes.timeBox}>
                    <img src={Prompt} alt="Prompt" />
                    <div className={classes.timeBoxText}>
                        This QR code, based on Bitcoin and Ethereum, can cryptographically prove that your current time is after&nbsp;{time}.
                    </div>
                </Box>
                <Box className={classes.QRboxItem}>
                    <div style={{
                        position: "absolute",
                        width: "180px",
                        height: "6px",
                        top: "-5px",
                        background: "#F9F9F9",
                    }}></div>
                    <div style={{
                        position: "absolute",
                        width: "180px",
                        height: "6px",
                        bottom: "-5px",
                        background: "#F9F9F9",
                    }}></div>
                    <div style={{
                        position: "absolute",
                        width: "6px",
                        height: "180px",
                        left: "-5px",
                        background: "#F9F9F9",
                    }}></div>
                    <div style={{
                        position: "absolute",
                        width: "6px",
                        height: "180px",
                        right: "-5px",
                        background: "#F9F9F9",
                    }}></div>
                    <QRCode
                        value={QRvalue}
                        onClick={() => setOpen(true)}
                        style={{
                            width: "180px",
                            height: "180px",
                            cursor: "pointer",
                        }}
                        fgColor={colorArr[colorArrIndex] || "#77B1C0"}
                    />
                    <Box style={{
                        width: "180px",
                        height: "20px",
                        background: "#FFFFFF",
                        opacity: 0.5,
                        borderRadius: "15px",
                        textAlign: "center",
                        lineHeight: "22px",
                        position: "absolute",
                        top: "44%",
                    }}></Box>
                    <Box style={{
                        position: "absolute",
                        top: "45%",
                        fontSize: "14px",
                        opacity: 0.6
                    }}>
                        {time}
                    </Box>
                </Box>
                <Search stampId={stampId} qrCodeColor={colorArr[colorArrIndex]}/>
            </Box>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth='lg'
                className={classes.dialogBox}
            >
                <DialogTitle >
                    <span>Details</span>
                    <CloseIcon onClick={() => { setOpen(false) }} className={classes.BackIcon} />
                </DialogTitle>
                <DialogContent>
                    <Box>
                        {QRdata && <ReactJson
                            src={JSON.parse(QRdata)}
                            name={false}
                            style={{ fontSize: "1rem" }}
                            collapsed={false}
                            shouldCollapse={() => false}
                            collapseStringsAfterLength={isMobile ? 10 : 170}
                            displayObjectSize={false}
                            displayDataTypes={false}
                            enableClipboard={false}
                        />}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default QRcode

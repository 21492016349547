import React, { useEffect, useState, useCallback } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg from "../../../images/portal/UT_token/bg-token.png";
import { getToken } from "../js/portal.service";
import copy from "copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import SnackbarMessage from "../../../components/Snackbar/Snackbar";
import "../css/token.css";
import ImportKeyDialog from "./ImportKeyDialog";
import Skeleton from "@mui/material/Skeleton";
import { getStoredValue } from "../../../utils/utils"
import SuperButton from "../../../components/Button/SuperButton";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    fontFamily: "Avenir",
    fontStyle: "normal",
  },
  contentBox: {
    width: "400px",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "10px 20px",
    },
  },
  content: {
    width: "400px",
    borderRadius: "12px",
    marginBottom: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "800",
    fontSize: "20px",
    textAlign: "center",
    width: "200px",
  },
  text: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#555555",
    textAlign: "center",
    marginTop: "19px",
  },
  keyBox: {
    width: "400px",
    height: "240px",
    margin: "0 auto",
    boxSizing: "border-box",
    paddingTop: "2px",
    backgroundImage: `url(${bg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    position: "relative",
    borderRadius: "12px",
    padding: "0 20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  token: {
    fontWeight: "800",
    fontSize: "24px",
    color: "#FFFFFF",
    textAlign: "center",
    marginBottom: "23px",
  },
  tokenContent: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#FFFFFF",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  copyBox: {
    marginLeft: "16px",
    cursor: "pointer",
    borderRadius: "12px",
    display: "inline-block",
    textAlign: "center",
  },
  licenseKeyBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  copyText: {
    textAlign: "center",
    position: "absolute",
    left: "140px",
    top: "70px",
    color: "#00ff00",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100px",
  },
}));
interface Token {
  count: number;
  created_time: number;
  enable: boolean;
  license_key: string;
  priority: number;
}
const Tokens: React.FC = () => {
  const classes = useStyles();
  const [tokenList, setTokenList] = useState<Array<Token>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isCopyDone, setIsCopyDone] = useState<boolean>(false);
  const [copiedIndex, setCopiedIndex] = useState<number>();
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const storedAccessToken = getStoredValue("UTStamp_access_token") || "";
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const getKey = useCallback(async () => {
    setLoading(true);
    const res = await getToken();
    console.log("token res", res);
    if (res === undefined) getKey();
    if (res?.status === 200) {
      setTokenList(res.data);
    } else {
      setAlertMessage(res?.data?.message);
      setSeverity("error");
      setIsOpen(true);
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    getKey();
  }, [getKey, storedAccessToken]);
  //复制
  const goCopy = (value: any, index: number) => {
    setCopiedIndex(index);
    if (copy(value)) {
      setIsCopyDone(true);
      setAlertMessage("Copy successfully");
      setSeverity("success");
      setIsOpen(!isOpen);
      const timer = setTimeout(() => {
        setIsCopyDone(false);
        clearTimeout(timer);
      }, 5000);
    } else {
      setIsCopyDone(false);
    }
  };
  const changeTime = (time: number) => {
    const date = new Date(time);
    const localDateString = date.toLocaleDateString();
    return `${localDateString}`;
  };

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.titleBox}>
          <Box
            style={{
              width: "180px",
            }}
          />
          <p className={classes.title}>
            {loading ? "loading..." : "Your Tokens"}
          </p>
          <SuperButton
            loading={false}
            value="Import"
            handleClick={() => setOpen(true)}
            UIType="confirm"
            option={{
              width:"120px",
              height:"44px",
              margin: "0 30px 0 0"
            }}
          />
        </Box>
        {loading ? (
          <Box className={classes.contentBox}>
            {[1].map((_item, index) => (
              <Skeleton
                className={classes.content}
                key={index}
                variant="rectangular"
                height={280}
                style={{ marginBottom: "30px" }}
              />
            ))}
          </Box>
        ) : (
          <Box
            className={classes.contentBox}
            display={{ display: loading ? "none" : "" }}
          >
            {tokenList.map((item, index) => (
              <Box className={classes.content} key={index}>
                <Box className={classes.keyBox}>
                  <p className={classes.token}>Token</p>
                  <Box className={classes.licenseKeyBox}>
                    <span className={classes.tokenContent}>
                      {item.license_key}
                    </span>
                    <Box
                      className={classes.copyBox}
                      onClick={() => goCopy(item.license_key, index)}
                    >
                      {isCopyDone && index === copiedIndex ? (
                        <DoneIcon
                          sx={{
                            marginTop: "7.5px",
                            color: "rgb(255 255 255 / 80%)",
                          }}
                        />
                      ) : (
                        <ContentCopyIcon
                          sx={{
                            marginTop: "7.5px",
                            color: "rgb(255 255 255 / 80%)",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <p
                    className={classes.tokenContent}
                    style={{ marginTop: "80px" }}
                  >
                    Remaining count : {item.count}
                  </p>
                </Box>
                <p className={classes.text}>
                  {`Your active token. Created on ${changeTime(
                    item.created_time
                  )}.`}
                </p>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <ImportKeyDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleRefresh={() => {
          getKey();
        }}
      />
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={1000}
        isOpen={isOpen}
      />
    </>
  );
};
export default Tokens;

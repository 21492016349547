import { configureStore } from '@reduxjs/toolkit';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
const initialState = {
    username: null,
    nickname: null,
    avatar: null,
    email: null,
    // loginMethod: null,
};

//reducer
function reducer(state = initialState, action: any) {
    switch (action.type) {
        case 'SET_USERNAME':
            return { ...state, username: action.payload };
        case 'SET_NICKNAME':
            return { ...state, nickname: action.payload };
        case 'SET_AVATAR':
            return { ...state, avatar: action.payload };
        case 'SET_EMAIL':
            return { ...state, email: action.payload };
        // case 'SET_LOGIN_METHOD':
        //     return { ...state, loginMethod: action.payload };
        case 'DELETE_USERNAME':
            return { ...state, username: null };
        case 'DELETE_NICKNAME':
            return { ...state, nickname: null };
        case 'DELETE_AVATAR':
            return { ...state, avatar: null };
        case 'DELETE_EMAIL':
            return { ...state, email: null };
        // case 'DELETE_LOGIN_METHOD':
        //     return { ...state, loginMethod: null };
        case 'CLEAR_USER_DATA':
            return {
                email: null, avatar: null, username: null, nickname: null
            };
        default:
            return state;
    }
}
const persistConfig = {
    key: 'root',
    storage,
};
const persistedReducer = persistReducer(persistConfig, reducer);
//store
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});
const persistor = persistStore(store);

export { store, persistor };

import { Box, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTheme } from '@mui/material/styles';
const useStyles = makeStyles((theme: Theme) => ({
    viewBox: {
        display: "flex",
        margin: "10px 0 20px",
        color: "#307DCF !important",
    },
    viewItem: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginRight: "34px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "0",
        }
    }
}))
interface Props {
    value: string,
    time: string,
    submissionHashValue: string,
    note?: string,
    option?: {
        margin: string,
    }
}

const DataDisplay = (Props: Props) => {
    const classes = useStyles()
    const { value, time, submissionHashValue, note, option } = Props
    const encodedValue = encodeURIComponent(value);
    const encodedTime = encodeURIComponent(time);
    const encodedSubmissionHashValue = encodeURIComponent(submissionHashValue);
    const encodedNote = encodeURIComponent(note || '');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleDownload = () => {
        const element = document.createElement("a");
        const file = new Blob([value], { type: 'json/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "UTStamp Chain.json";
        document.body.appendChild(element);
        element.click();
    }
    return (
        <>
            {value ?
                <Box className={classes.viewBox}
                    style={{
                        display: value ? "" : "none",
                        margin: option?.margin || "10px 0 20px",
                    }}>
                    <Box
                        className={classes.viewItem}
                        onClick={handleDownload}
                        style={{marginRight: isMobile ? "10px" : ""}}
                    >
                        <ArrowDownwardIcon sx={{ marginRight: "8px" }} />
                        <span>{isMobile ? "Download" : "Download UTStamp Chain"}</span>
                    </Box>
                    <Box
                        className={classes.viewItem}
                        onClick={
                            () => {
                                const url = `/certificate?value=${encodedValue}&time=${encodedTime}&submissionHashValue=${encodedSubmissionHashValue}&note=${encodedNote}`;
                                window.open(url, '_blank');
                            }
                        }
                    >
                        <RemoveRedEyeIcon sx={{ marginRight: "8px" }} />
                        <span>{isMobile ? "View" : "View UTStamp Chain"}</span>
                    </Box>
                </Box> : <span style={{ height: "30px" }}>loading...</span>}
        </>
    )
}
export default DataDisplay

import { Box, Theme, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { changeTime } from "../../../utils/changeTime";
import DataDisplay from "../../home/DataDisplay";

const useStyles = makeStyles((theme: Theme) => ({
  dialogBox: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  BackIcon: {
    cursor: "pointer",
  },
}));

interface submissionDetailProps {
  info: {
    content: string;
    hash: string;
    notes: string;
    createTime: number;
    queryData: any;
    price: number;
  };
  open: boolean;
  handleClose: () => void;
  handleRefresh?: () => void;
}

const SubmissionDetailDialog = (props: submissionDetailProps) => {
  const classes = useStyles();
  const { open, handleClose, info } = props;
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      className={classes.dialogBox}
      maxWidth="lg"
    >
      <DialogTitle>
        <span>Details</span>
        <CloseIcon onClick={() => handleClose()} className={classes.BackIcon} />
      </DialogTitle>
      <DialogContent>
        <Box style={{ minWidth: "40vw", minHeight: "35vh" }}>
          <Divider />
          {info?.price && (
            <div>
              <h4 style={{ margin: "27px 0 10px" }}>Consumed tokens:</h4>
              <span>{info?.price}</span>
            </div>
          )}
          {info?.content && (
            <div>
              <h4 style={{ margin: "27px 0 10px" }}>Content:</h4>
              <span>{info?.content}</span>
            </div>
          )}
          {info?.hash && (
            <div>
              <h4 style={{ margin: "27px 0 10px" }}>
                Content Hash for stamping:
              </h4>
              <span
                style={{
                  width: "100%",
                  wordBreak: "break-all",
                }}
              >
                {info?.hash}
              </span>
            </div>
          )}
          {info?.notes && (
            <div>
              <h4 style={{ margin: "27px 0 10px" }}>Notes:</h4>
              <span>{info?.notes}</span>
            </div>
          )}
          {info?.createTime && (
            <div>
              <h4 style={{ margin: "27px 0 10px" }}>Create Time:</h4>
              <span>{changeTime(info?.createTime)}</span>
            </div>
          )}
          <DataDisplay
            value={info?.queryData}
            time={changeTime(info?.createTime)}
            submissionHashValue={info?.hash}
            note={info?.notes}
            option={{
              margin: "27px 0 10px",
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubmissionDetailDialog;

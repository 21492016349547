import React, { FC, useState } from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
const Alert: FC<AlertProps> = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};
interface Props {
    isOpen: boolean;
    message: string;
    severity?: 'error' | 'warning' | 'info' | 'success'; 
    duration?: number;
}
const SnackbarMessage: FC<Props> = ({ message, severity = 'info', duration = 1000, isOpen = false }) => {
    const [open, setOpen] = useState<boolean>(false);
    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        setOpen(false);
    };
    React.useEffect(() => {
        if(message){
            setOpen(true);
        }
    }, [message, isOpen]);
    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => handleClose()}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            <div>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </div>
        </Snackbar>
    );
};

export default SnackbarMessage;

import React from "react";
import { Theme, Box, TextField, Tooltip, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((_theme: Theme) => ({
  content: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "25px 0",
  },
  inputStyle: {
    width: "120px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      height: "44px",
    },
  },
  tipText: {
    color: "#307DCF",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    textDecorationLine: "underline",
  },
  tokenText: {
    color: "#FFF",
    fontFamily: "Avenir",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    padding: "10px",
    "& p": {
      margin: "0",
    },
  },
  title: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "900",
    lineHeight: "20px",
    marginRight: "10px",
  },
}));

interface Props {
  handleValue: (value: List) => void;
}

interface List {
  level: string;
  levelIndex: number;
  tokens: string;
  delay: string;
}

const list: List[] = [
  {
    level: "P0",
    tokens: "72",
    delay: "20",
    levelIndex: 0,
  },
  {
    level: "P1",
    tokens: "24",
    delay: "60",
    levelIndex: 1,
  },
  {
    level: "P2",
    tokens: "12",
    delay: "120",
    levelIndex: 2,
  },
  {
    level: "P3",
    tokens: "6",
    delay: "240",
    levelIndex: 3,
  },
  {
    level: "P4",
    tokens: "3",
    delay: "480",
    levelIndex: 4,
  },
  {
    level: "P5",
    tokens: "1",
    delay: "1440",
    levelIndex: 5,
  },
];

const SeletedLevel = (props: Props) => {
  const classes = useStyles();
  const [currentLevel, setCurrentLevel] = React.useState<List>(list[5]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentLevel(list.find((item) => item.level === event.target.value)!);
    props.handleValue(list.find((item) => item.level === event.target.value)!);
  };

  return (
    <>
      <Box className={classes.content}>
        <p className={classes.title}>Level:</p>
        <TextField
          select
          size="small"
          placeholder="Select Level"
          value={currentLevel.level}
          onChange={handleChange}
          className={classes.inputStyle}
        >
          {list.map((item, index) => (
            <MenuItem value={item.level} key={index}>
              {item.level}
            </MenuItem>
          ))}
        </TextField>
        <Tooltip
          title={
            <Box className={classes.tokenText}>
              <p style={{ marginBottom: "6px" }}>
                Consumed tokens:&nbsp;{currentLevel?.tokens}
              </p>
              <p>Expect delay:&nbsp;{currentLevel?.delay}&nbsp;mins</p>
            </Box>
          }
          arrow
          placement="right"
        >
          <span className={classes.tipText} style={{ marginLeft: "10px" }}>Tips</span>
        </Tooltip>
      </Box>
    </>
  );
};

export default SeletedLevel;

import { useState, useEffect, useCallback } from "react";
import {
  useMediaQuery,
  Box,
  Theme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import quit from "../../images/uploadFiles2/ico-quit.svg";
import token from "../../images/uploadFiles2/ico-token.svg";
import portal from "../../images/uploadFiles2/ico-portal.svg";
import profile from "../../images/uploadFiles2/ico-profile.svg";
import { getStoredAccessToken } from "../../utils/localStorage";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import "./userInfo.css";
import { getUserInfo } from "./userInfo.service";
import {
  setUsername,
  setNickname,
  setAvatar,
  setEmail,
  clearUserData,
} from "../../redux/actions/actions";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import UserPool from "../../cognito/userpool";
import { clearStoredValue, getStoredValue } from "../../utils/utils";
import SuperButton from "../Button/SuperButton";

const useStyles = makeStyles((theme: Theme) => ({
  userInfo: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    cursor: "pointer",
  },
  userInfoCard: {
    width: "156px",
    position: "absolute",
    top: "50px",
    right: "-5px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    zIndex: 9,
    borderRadius: "5px",
    padding: "15px 10px",
  },
  userInfoCardItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: "40px",
    justifyContent: "flex-start",
    padding: "0 10px",
    "&:hover": {
      background: "#F9F9F9",
    },
  },
  userInfoText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    margin: "0 8px",
    display: "inline-block",
    maxWidth: "170px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "50px",
    },
  },
  triangle: {
    width: "12px",
    height: "12px",
    position: "absolute",
    top: "-6px",
    right: "9px",
    transform: "rotate(45deg)",
    background: "#FFFFFF",
    zIndex: 10,
  },
  BackIcon: {
    cursor: "pointer",
  },
  dialogBox: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    "& .css-1fu2e3p-MuiPaper-root-MuiDialog-paper": {
      padding: "10px",
    },
    "& .css-18i3v7t": {
      padding: "10px",
    },
  },
  title: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    color: "#000000",
  },
}));
const UserInfo = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const loginMethod = getStoredValue("loginMethod");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isExpandUserInfo, setIsExpandUserInfo] = useState<boolean>(false);
  const isLogIn = getStoredAccessToken();
  const username = useSelector((state: any) => state.username);
  const nickname = useSelector((state: any) => state.nickname);
  const userAvatar = useSelector((state: any) => state.avatar);
  const [open, setOpen] = useState(false);

  const getInfo = useCallback(async () => {
    try {
      const res = await getUserInfo();
      if (!res?.data) return;
      dispatch(setAvatar(res.data?.avatar));
      dispatch(setNickname(res.data?.nickname));
      dispatch(setUsername(res.data?.username));
      dispatch(setEmail(res.data?.email));
    } catch (e: any) {
      console.log("error", e);
    }
  }, [dispatch]);
  useEffect(() => {
    getInfo();
    document.addEventListener("click", () => {
      setIsExpandUserInfo(false);
    });
  }, [getInfo]);
  const logOut = async () => {
    clearUserData("");
    clearStoredValue();
    if (loginMethod === "email") {
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
        Username: username,
        Pool: UserPool,
      });
      cognitoUser.signOut();
    }
    navigate("/login");
  };
  return (
    <>
      <Box className="userInfo" style={{ display: isLogIn ? "" : "none" }}>
        <Box
          className={classes.userInfo}
          onClick={(e) => {
            setIsExpandUserInfo(!isExpandUserInfo);
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          <Avatar
            sx={{
              cursor: "pointer",
              backgroundColor: "rgb(137 211 245)",
              color: "rgb(63, 142, 180)",
              fontFamily: "Avenir",
              fontStyle: "normal",
              fontWeight: 700,
            }}
            src={userAvatar}
          >
            {nickname?.charAt(0).toUpperCase() ||
              username?.charAt(0).toUpperCase()}
          </Avatar>
          <span className={classes.userInfoText}>{nickname || username}</span>
          {isExpandUserInfo ? (
            <KeyboardArrowUpIcon sx={{ cursor: "pointer" }} />
          ) : (
            <KeyboardArrowDownIcon
              className="arrow"
              sx={{ cursor: "pointer" }}
            />
          )}
          <Box className={classes.userInfoCard} hidden={!isExpandUserInfo}>
            {isMobile && (
              <Box
                className={classes.userInfoCardItem}
                onClick={() => navigate("/portal")}
              >
                <img
                  src={portal}
                  alt="Portal"
                  style={{
                    width: "18px",
                    height: "18px",
                    marginRight: "11px",
                  }}
                />
                <span>Portal</span>
              </Box>
            )}
            <Box
              className={classes.userInfoCardItem}
              onClick={() => navigate("/portal/tokens")}
            >
              <img src={token} alt="token" style={{ marginRight: "11px" }} />
              <span>My Tokens</span>
            </Box>
            <Box
              className={classes.userInfoCardItem}
              onClick={() => navigate("/portal/profile")}
            >
              <img
                src={profile}
                alt="Profile"
                style={{ marginRight: "11px" }}
              />
              <span>My Profile</span>
            </Box>
            <Box
              className={classes.userInfoCardItem}
              onClick={() => setOpen(true)}
            >
              <img src={quit} alt="quit" style={{ marginRight: "11px" }} />
              <span>Log Out</span>
            </Box>
            <div className={classes.triangle}></div>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        className={classes.dialogBox}
        maxWidth="lg"
      >
        <DialogTitle>
          <span className={classes.title}>Log out UTStamp?</span>
          <CloseIcon
            onClick={() => {
              setOpen(false);
            }}
            className={classes.BackIcon}
          />
        </DialogTitle>
        <DialogContent>
          <Box style={{ minWidth: "30vw", minHeight: "8vh" }}>
            <Divider />
            <span
              style={{
                fontWeight: "400",
                fontSize: "14px",
                display: "block",
                marginTop: "20px",
              }}
            >
              After logging out, you will not be able to stamp your own texts
              and files.
            </span>
          </Box>
        </DialogContent>
        <DialogActions>
          <SuperButton
            loading={false}
            value="Cancel"
            handleClick={() => setOpen(false)}
            UIType="cancel"
            option={{
              width: "120px",
              height: "40px",
              margin: "0 15px 0 0",
            }}
          />
          <SuperButton
            loading={false}
            value="Log Out"
            handleClick={() => logOut()}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UserInfo;

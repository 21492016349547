import React, { useState, useRef } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Upload from "../../images/uploadFiles2/ico-upload.svg";
import Icon from "../../images/uploadFiles2/ico.svg";
import successful from "../../images/uploadFiles2/ico-successful.svg";
import failed from "../../images/uploadFiles2/ico-failed.svg";
import wait from "../../images/uploadFiles2/wait.svg";
import networkError from "../../images/uploadFiles2/networkerror.svg";
import prompt from "../../images/uploadFiles2/ico-prompt.svg";
import sha256 from "crypto-js/sha256";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import DataDisplay from "./DataDisplay";
import { getStoredAccessToken } from "../../utils/localStorage";
import { handleSubmitHash, handleQueryHash } from "./js/Home.service";
import Dialog from "./Dialog";
import "./css/home.css";
import { changeTime } from "../../utils/changeTime";
import SuperButton from "../../components/Button/SuperButton";
import SeletedLevel from "../../components/seletedLevel/SeletedLevel";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
  },
  inputAreaItem: {
    width: "100%",
    height: "100%",
  },
  btnBox: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
  },
  NotesBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
  },
  selectBox: {
    width: "100%",
    height: "340px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
  },
  UploadBox: {
    height: "332px",
    border: "1px solid #b7b7b7",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  tipBox: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: "25px",
    maxWidth: "80%",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
      padding: "0 10px",
    },
  },
  tipBoxText: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    margin: "0 0 0 8px",
    display: "inline-block",
    textAlign: "start",
  },
}));

const FileArea = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isLogIn = getStoredAccessToken();
  const inputEl = useRef<HTMLInputElement>(null);
  const [showTextInputValue, setShowTextInputValue] = useState<number>(1);
  const [submitFileHashLoading, setSubmitFileHashLoading] =
    useState<boolean>(false);
  const [fileHashQueryTime, setFileHashQueryTime] = useState<any>();
  const [fileHashSubmitRecord, setFileHashSubmitRecord] = useState<any>();
  const [fileHashValue, setFileHashValue] = useState<string>("");
  const [fileNotes, setFileNotes] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [fileSubmissionHash, setFileSubmissionHash] = useState<string>("");
  const [filetWaitMessage, setFiletWaitMessage] = useState<string>("");
  const [fileIsLoading, setFileIsLoading] = useState<boolean>(false);
  const [queryFileHashLoading, setQueryFileHashLoading] =
    useState<boolean>(false);
  const [level, setLevel] = useState<number>(5);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");

  const handleFileNoteValue = (event: any) => {
    const newText = event.target.value;
    setFileNotes(newText);
  };

  const handleClear = () => {
    if (inputEl.current) {
      inputEl.current.value = "";
    }
    setFileName("");
    setFileHashValue("");
    setFileNotes("");
  };

  const handleFileHashRestartSubmit = () => {
    handleClear();
    setShowTextInputValue(1);
  };
  const handelSubmitFileHash = async () => {
    if (!isLogIn) {
      navigate("/login");
      return;
    }
    setSubmitFileHashLoading(true);
    const response = await handleSubmitHash(fileHashValue, fileNotes, level);
    const isSubmitSuccess = response?.data?.all_success;
    if (isSubmitSuccess) {
      setShowTextInputValue(14);
      setSubmitFileHashLoading(false);
    } else {
      setErrorMessage(response?.data?.message);
      setShowTextInputValue(15);
      setSubmitFileHashLoading(false);
    }
  };
  const handleRestartFileHashSubmit = () => {
    handleClear();
    setShowTextInputValue(1);
    setFileHashValue("");
    setFileNotes("");
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFileIsLoading(true);
    const file = event.target.files && event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async (event: ProgressEvent) => {
        setFileName(file.name);
        const res: string = reader.result as string;
        let hash: string = sha256(res).toString();
        if (hash) {
          setFileHashValue(hash);
        }
        setFileIsLoading(false);
      };
      reader.readAsText(file as Blob);
    }
  };
  const uploadFile = () => {
    if (inputEl.current) {
      inputEl.current.click();
    }
  };

  const handleQueryFileHashValue = (queryStatus: number) => {
    if (queryStatus) {
      if (queryStatus === 200) {
        setShowTextInputValue(10);
      } else if (queryStatus === 404) {
        setShowTextInputValue(11);
      } else if (queryStatus === 408) {
        setShowTextInputValue(12);
      } else if (queryStatus === 500) {
        setShowTextInputValue(13);
      } else {
        setAlertMessage("error");
        setIsOpen(!isOpen);
      }
    }
  };

  const queryFileHashValue = async (hashValue: string) => {
    setQueryFileHashLoading(true);
    const response = await handleQueryHash(hashValue);
    const queryStatus = response?.status;
    const successTime = response?.data?.timestamp;
    const time = changeTime(successTime);
    if (queryStatus === 408) {
      setFiletWaitMessage(response?.data?.message);
    }
    setFileHashSubmitRecord(JSON.stringify(response?.data));
    setFileHashQueryTime(time);
    setQueryFileHashLoading(false);
    handleQueryFileHashValue(queryStatus);
    setFileSubmissionHash(response?.data?.submission_hash);
  };

  const queryFileHash = () => {
    if (fileHashValue) {
      queryFileHashValue(fileHashValue);
    } else {
      setAlertMessage("Please Select a file");
      setSeverity("info");
      setIsOpen(!isOpen);
    }
  };

  return (
    <Box className={classes.content}>
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 1}>
        <Box className={classes.UploadBox}>
          {fileIsLoading ? (
            <CircularProgress />
          ) : (
            <img
              src={Upload}
              alt="Upload"
              style={{ width: "40px", height: "36px", cursor: "pointer" }}
              onClick={uploadFile}
            />
          )}
          <p>Stamp File</p>
          {fileName && (
            <p
              style={{
                height: "40px",
                borderRadius: "12px",
                padding: "5px 10px",
                border: "1px solid #ccc",
                lineHeight: "40px",
                margin: "0",
              }}
            >
              selected file:&nbsp;{fileName}
            </p>
          )}
          <input
            ref={inputEl}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <div className={classes.tipBox}>
            <img src={prompt} alt="prompt" />
            <span className={classes.tipBoxText}>
              Please note that we only generate the hash for the selected file
              and send the hash to our backend for stamping. The file itself
              will not be stored or sent to our server.
            </span>
          </div>
        </Box>
        <Box className={classes.btnBox}>
          <TextField
            size="small"
            value={fileNotes}
            multiline
            fullWidth
            onChange={handleFileNoteValue}
            placeholder="Notes (Optional)"
            color="info"
            className={classes.NotesBox}
          />
          <SuperButton
            loading={queryFileHashLoading}
            value="Query"
            handleClick={queryFileHash}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
              margin: "0 0 0 20px",
            }}
          />
        </Box>
      </Box>
      {/* file hash已盖章 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 10}>
        <Box className={classes.selectBox}>
          <img
            src={prompt}
            alt="Icon"
            style={{ width: "32px", height: "32px", position: "relative" }}
          />
          <p>
            The file has been stamped at {fileHashQueryTime}.<br /> Please click
            'Restart' for a new query, or select 'Return' to revisit the
            existing query.
          </p>
          <DataDisplay
            value={fileHashSubmitRecord}
            time={fileHashQueryTime}
            submissionHashValue={fileSubmissionHash}
            note={fileNotes}
          />
          <Box>
            <SuperButton
              loading={false}
              value="Restart"
              handleClick={handleFileHashRestartSubmit}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            <SuperButton
              loading={false}
              value="Return"
              handleClick={() => setShowTextInputValue(1)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 0 0 20px",
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* file hash未盖章区域 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 11}>
        <Box className={classes.selectBox}>
          <img
            src={Icon}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p style={{ marginBottom: "0" }}>
            This file is not stamped, do you want to stamp it now?
          </p>
          <SeletedLevel
            handleValue={(value: any) => {
              setLevel(value?.levelIndex);
            }}
          />
          <Box style={{ display: "flex" }}>
            <SuperButton
              loading={submitFileHashLoading}
              value="Stamp It!"
              handleClick={handelSubmitFileHash}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => setShowTextInputValue(1)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 0 0 20px",
              }}
            />
          </Box>
          <Dialog
            hashValue={fileHashValue}
            textNotes={fileNotes}
            filename={fileName}
          />
        </Box>
      </Box>
      {/* file hash等一等 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 12}>
        <Box className={classes.selectBox}>
          <img
            src={wait}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          {/* <p>This file message was timestamped at {filetWaitTime}, please check back later.</p> */}
          <p>{filetWaitMessage}</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(1)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
      </Box>
      {/* file hash网络错误 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 13}>
        <Box className={classes.selectBox}>
          <img
            src={networkError}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>Network Error</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(1)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
      </Box>
      {/* file hash盖章成功 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 14}>
        <Box className={classes.selectBox}>
          <img
            src={successful}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>This file is stamped successfully!</p>
          <SuperButton
            loading={false}
            value="Restart"
            handleClick={handleRestartFileHashSubmit}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
          <Dialog
            hashValue={fileHashValue}
            textNotes={fileNotes}
            filename={fileName}
          />
        </Box>
      </Box>
      {/* file hash盖章失败 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 15}>
        <Box className={classes.selectBox}>
          <img
            src={failed}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>This file is stamped unsuccessfully!</p>
          {errorMessage && <p style={{ marginTop: "0" }}>{errorMessage}</p>}
          <Box style={{ display: "flex" }}>
            <SuperButton
              loading={false}
              value="Stamp again!"
              handleClick={() => setShowTextInputValue(1)}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => setShowTextInputValue(1)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 0 0 20px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={3000}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default FileArea;

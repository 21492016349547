import React, { useState } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../images/shared/Logo.svg";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { addUserAttribute } from "../../utils/utils";
import UserPool from "../../cognito/userpool";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import { isValid } from "../../utils/tool";
import SuperButton from "../../components/Button/SuperButton";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  logo: {
    margin: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      textAlign: "center",
    },
  },
  content: {
    width: "400px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    margin: "5vh auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 40px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto 5vh",
    },
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#000000",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
  loginWay: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    border: "1px solid #E6E6E6",
    borderRadius: "24px",
    margin: "8px 0",
  },
  inputStyle: {
    width: "100%",
    height: "44px",
    margin: "8px 0 !important",
    "& .MuiInputBase-root": {
      borderRadius: "24px",
      fontSize: "14px",
    },
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "12px 14px",
    },
    "& .css-1o6z5ng": {
      padding: "12px 14px",
    },
  },
  signUpBtn: {
    width: "100%",
    height: "44px",
    background: "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%) !important",
    boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.3) !important",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
  signUpInfo: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "center",
  },
  backBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  signBtn: {
    width: "100%",
    height: "44px",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
}));

const SignUp: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userNameValue, setUserNameValue] = useState<string>("");
  const [userEmailValue, setUserEmailValue] = useState<any>();
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const signUp = () => {
    if (
      !userNameValue ||
      !userEmailValue ||
      !passwordValue ||
      !confirmPassword
    ) {
      setAlertMessage("Please fill in all the blanks");
      setIsOpen(!isOpen);
      return;
    }
    if (!isValid(userEmailValue)) {
      setAlertMessage("Please enter a valid email address");
      setIsOpen(!isOpen);
      return;
    }
    if (passwordValue !== confirmPassword) {
      setAlertMessage("The passwords entered twice are different!");
      setIsOpen(!isOpen);
      return;
    }
    setLoading(true);
    const attributeList: any[] = [];
    attributeList.push(addUserAttribute("email", userEmailValue.trim()));
    UserPool.signUp(
      userNameValue.trim(),
      passwordValue.trim(),
      attributeList,
      [],
      (err, data) => {
        if (err) {
          setLoading(false);
          console.log("err", err);
          setAlertMessage(`${err.message}`);
          setIsOpen(!isOpen);
        } else {
          setAlertMessage("SignUp Successful! Please confirm the register!");
          setIsOpen(!isOpen);
          setLoading(false);
          console.log("data", data);
          navigate(`/confirm`, {
            state: {
              username: userNameValue.trim(),
              password: passwordValue.trim(),
            },
          });
        }
      }
    );
  };
  const signUpKeyDown = (e: any) => {
    if (e.key === "Enter") {
      signUp();
    }
  };
  return (
    <>
      <Box
        className={classes.root}
        style={{
          pointerEvents: loading ? "none" : "auto",
        }}
      >
        <Box className={classes.logo}>
          <img src={logo} alt="network error" className="logo" />
        </Box>
        <Box className="home">
          <Box className={classes.content}>
            <Box className={classes.backBox}>
              <ArrowBackIcon
                onClick={() => navigate("/login")}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              />
              <Box className={classes.title}>Sin Up</Box>
              <div style={{ width: "24px", height: "24px" }} />
            </Box>
            <TextField
              onKeyDown={signUpKeyDown}
              size="small"
              type="text"
              placeholder="Username"
              onChange={(e) => setUserNameValue(e.target.value)}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={signUpKeyDown}
              size="small"
              type="email"
              placeholder="Email"
              onChange={(e) => setUserEmailValue(e.target.value)}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={signUpKeyDown}
              size="small"
              type="password"
              placeholder="Password"
              onChange={(e) => setPasswordValue(e.target.value)}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={signUpKeyDown}
              size="small"
              type="password"
              placeholder="Re-enter password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className={classes.inputStyle}
            />
            <SuperButton
              loading={loading}
              UIType="confirm"
              value="Sign Up"
              handleClick={signUp}
              option={{
                margin: "13px 0 0 0",
              }}
            />
            {/* <p className={classes.signUpInfo}>
                        By clicking “sign up”, you agree to our&nbsp;
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href=""
                            style={{
                                textDecoration: "underline",
                                color: "black",
                                cursor: "pointer"
                            }}
                        >
                            Privacy Policy
                        </a> &nbsp;and&nbsp;
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href=""
                            style={{
                                textDecoration: "underline",
                                color: "black",
                                cursor: "pointer"
                            }}
                        >
                            Terms of Service
                        </a>.
                    </p> */}
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "#E6E6E6",
                marginTop: "30px",
              }}
            ></div>
            <p className={classes.signUpInfo}>
              Already have an account?&nbsp;
              <span
                onClick={() => navigate("/login")}
                style={{
                  textDecorationLine: "underline",
                  color: "#307DCF",
                  cursor: "pointer",
                }}
              >
                Login
              </span>
            </p>
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={"info"}
        duration={1000}
        isOpen={isOpen}
      />
    </>
  );
};
export default SignUp;

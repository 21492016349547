import { CognitoUserPool } from "amazon-cognito-identity-js";
import { ApiConfig } from "../utils/apiConfig";
const UserPoolId: string = ApiConfig().REACT_APP_POOLDATA_USERPOOLID!;
const ClientId: string = ApiConfig().REACT_APP_POOLDATA_CLIENTID!;
export const PoolData = {
    UserPoolId,
    ClientId,
    Region: "us-west-2"
}
const userPool = new CognitoUserPool(PoolData);

export default userPool
export const verifyJsonValue = async (jsonValue: any) => {
  const content = JSON.parse(jsonValue)
  // let oapMeta = content.oap?.meta
  let txHexData = content.stamp?.data
  let rootValue = content.merkleTreeRootKey
  let hashValue = content.targetHashValue
  if (rootValue === hashValue && txHexData === base64ToHexString(hashValue)) {
    // alert("success")
    return 1
  }
  for (const node of content.complementPath) {
    let left = node.position === 'RIGHT' ? hashValue : node.hashValue
    let right = node.position === 'RIGHT' ? node.hashValue : hashValue
    hashValue = await calculateMerkleTreeHashSum(left, right)
  }
  if (rootValue === hashValue && txHexData !== base64ToHexString(hashValue)) {
    // alert("failed")
    return 2
  } else {
    // alert("success")
    return 1
  }
}
/**
 * Calculate the hash sum value of two leafs in merkle trees
 * @param v1 base64 string of the left leaf
 * @param v2 base64 string of the right leaf
 */
export const calculateMerkleTreeHashSum = async (v1: any, v2: any) => {
  const left = base64ToUint8Array(v1);
  const right = base64ToUint8Array(v2);
  const msgUnit8 = new Uint8Array(left.byteLength + right.byteLength);
  msgUnit8.set(left, 0);
  msgUnit8.set(right, left.byteLength);
  // 使用传统的 for 循环遍历 Uint8Array 数组
  const msgArray = new Array(msgUnit8.length);
  for (let i = 0; i < msgUnit8.length; i++) {
    msgArray[i] = msgUnit8[i];
  }
  const hashBuffer = await crypto.subtle.digest('SHA-256', new Uint8Array(msgArray));
  return window.btoa(String.fromCharCode(...new Uint8Array(hashBuffer)));
}
const base64ToUint8Array = (base64String: any) => {
  if (!base64String || typeof base64String !== "string" || !/^[A-Za-z0-9+/]{4}[A-Za-z0-9+/]{0,3}=?$/.test(base64String)) {
    // alert("Invalid base64 string.")
  }
  const padding = '='.repeat((4 - base64String?.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
const base64ToHexString = (base64String: any) => {
  const array = base64ToUint8Array(base64String)
  return array.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '')
}
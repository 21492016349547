import React, { useState, useEffect } from "react";
import { Theme, Box, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import top from "../../images/certificate2/top.png";
import logo from "../../images/certificate2/Logo_UTS.svg";
import ReactJson from "react-json-view";
import CircularProgress from "@mui/material/CircularProgress";
import jsPDF from "jspdf";
import TreeViz from "../../components/TreeViz/TreeViz";
import GetAppIcon from "@mui/icons-material/GetApp";
import html2canvas from "html2canvas";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: "#fff",
    overflow: "hidden",
    width: "100%",
  },
  content: {
    maxWidth: "1280px",
    margin: "0 auto",
    background: "#fff",
    overflow: "hidden",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "100%",
    },
  },
  valueBox: {
    padding: "20px 100px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  value: {},
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  loadingBox: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff",
    flexDirection: "column",
  },
  operateItem: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    background: "#fff",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "60px",
    "&:hover": {
      background: "#F9F9F9",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
      marginBottom: "20px",
    },
  },
  operate: {
    position: "fixed",
    width: "80px",
    display: "inline-block",
    marginRight: "50px",
    right: "0",
    bottom: "0",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
      marginRight: "20px",
    },
  },
  titleBox: {
    fontFamily: "Avenir",
    fontSize: "16px",
    width: "100%",
    margin: "20px 0",
    wordWrap: "break-word",
    wordBreak: "break-all",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  title: {
    fontWeight: "bold",
    fontSize: "16px",
  },
  headerImg: {
    width: "100%",
  },
  noteValueText: {
    wordWrap: "break-word",
  },
}));

const Certificate: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [indexValue, setIndexValue] = useState<number>(0);
  const urlParams = new URLSearchParams(window.location.search);
  const encodedValue = urlParams.get("value");
  const encodedTime = urlParams.get("time");
  const submissionHashValue = urlParams.get("submissionHashValue");
  const note = urlParams.get("note");
  const value = decodeURIComponent(encodedValue!);
  const time = decodeURIComponent(encodedTime!);
  const submissionHashValueValue = decodeURIComponent(submissionHashValue!);
  const noteValue = decodeURIComponent(note!);
  let changedValue = value;
  if (changedValue) {
    changedValue = changedValue.replace(
      "merkle_root_key",
      "merkle_root(Hexadecimal)"
    );
    changedValue = changedValue.replace("complement_path", "path");
  }
  const parsedValue = JSON.parse(changedValue!);
  const classes = useStyles();
  const downloadPDF = () => {
    const printContent = document.getElementById("print-btn");
    const img = new Image();
    img.src = top; // 触发加载
    img.onload = () => {
      html2canvas(printContent!).then((canvas) => {
        const pdf = new jsPDF("p", "pt", [canvas.width / 3, canvas.height / 3]);
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width / 3, canvas.height / 3);
        pdf.save("UTStampChain.pdf");
      });
    };
  };
  useEffect(() => {
    setTimeout(() => {
      setIndexValue(1);
    }, 2000);
  }, []);
  return (
    <>
      {indexValue ? (
        <Box className={classes.root}>
          <Box className={classes.content} id="print-btn">
            <img src={top} alt="headerBg" className={classes.headerImg} />
            <Box className={classes.valueBox}>
              <Box>
                <TreeViz
                  path={parsedValue.path || []}
                  submissionHash={parsedValue.submission_hash}
                  markleRoot={parsedValue.merkle_root}
                />
                <Box className={classes.titleBox}>
                  <span className={classes.title}>Hash:&nbsp;</span>
                  {submissionHashValueValue}
                </Box>
                <Box className={classes.value}>
                  <span className={classes.title}>Chain:&nbsp;</span>
                  {value && (
                    <ReactJson
                      name={false}
                      src={parsedValue}
                      style={{ fontSize: "1rem" }}
                      collapsed={false}
                      displayObjectSize={false}
                      displayDataTypes={false}
                      enableClipboard={false}
                      collapseStringsAfterLength={isMobile ? 5 : 100}
                    />
                  )}
                </Box>
                {noteValue && (
                  <Box className={classes.titleBox}>
                    <span className={classes.title}>Notes:&nbsp;</span>
                    <span className={classes.noteValueText}>{noteValue}</span>
                  </Box>
                )}
                <div
                  style={{
                    height: "1px",
                    background: "#D9D9D9",
                    margin: "20px 0",
                  }}
                ></div>
              </Box>
              <Box className={classes.footer}>
                <Box>
                  {/* <p>Submitter: Panion</p> */}
                  <p>Date:&nbsp;{time}</p>
                </Box>
                <Box>
                  <img src={logo} alt="logo" />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.operate}>
            <Box className={classes.operateItem} onClick={downloadPDF}>
              <GetAppIcon sx={{ fontSize: isMobile ? "30px" : "40px" }} />
            </Box>
          </Box>
        </Box>
      ) : (
        <Box className={classes.loadingBox}>
          <CircularProgress />
          {isMobile ? (
            <h4
              style={{
                textAlign: "center",
              }}
            >
              Generating certificate, please wait a moment......
            </h4>
          ) : (
            <h1>Generating certificate, please wait a moment......</h1>
          )}
        </Box>
      )}
    </>
  );
};
export default Certificate;

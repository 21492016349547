import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Theme, Box } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles((theme: Theme) => ({
    content: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        margin: "40px 0",
        justifyContent: "flex-start",
        [theme.breakpoints.down('sm')]: {
            margin: "20px 0",
        }
    },
    text: {
        fontFamily: 'Avenir',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        marginLeft: "12px"
    }
}))
const BackBox = () => {
    const navigate = useNavigate();
    const classes = useStyles()
    return (
        <>
            <Box
                className={classes.content}
                onClick={() => navigate(-1)}>
                <ArrowBackIcon />
                <span className={classes.text}>Back</span>
            </Box>
        </>
    );
}
export default BackBox

import { useState } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import successful from "../../images/uploadFiles2/ico-successful.svg";
import failed from "../../images/uploadFiles2/ico-failed.svg";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import { verifyChain } from "../../utils/verifyJson";
import "./css/home.css";
import SuperButton from "../../components/Button/SuperButton";

const useStyles = makeStyles((_theme: Theme) => ({
  content: {
    width: "100%",
  },
  inputAreaItem: {
    width: "100%",
    height: "100%",
  },
  selectBox: {
    width: "100%",
    height: "340px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
  },
  NotesBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
  },
  textBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
  },
  btnBox: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
  },
}));

const ChainArea = () => {
  const classes = useStyles();
  const [showTextInputValue, setShowTextInputValue] = useState<number>(3);
  const [jsonLoading, setJsonLoading] = useState<boolean>(false);
  const [jsonValue, setJsonValue] = useState<any>();
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");

  //验证输入的是否是json
  const verifyIsJsonValue = (str: string) => {
    if (typeof str === "string") {
      try {
        const obj = JSON.parse(str);
        if (typeof obj === "object" && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        return false;
      }
    }
    return false;
  };
  const verifyJsonValue2 = async () => {
    if (jsonValue) {
      setJsonLoading(true);
      if (!verifyIsJsonValue(jsonValue)) {
        setAlertMessage("Please enter valid json");
        setIsOpen(!isOpen);
        setSeverity("info");
        setJsonLoading(false);
        setJsonValue("");
        return;
      }
      const res: any = await verifyChain(jsonValue);
      if (res) {
        if (res === 1) {
          setTimeout(() => {
            setJsonLoading(false);
            setShowTextInputValue(22);
          }, 2000);
        }
        if (res === 2) {
          setTimeout(() => {
            setJsonLoading(false);
            setShowTextInputValue(23);
          }, 2000);
        }
      } else {
        setJsonLoading(false);
      }
    } else {
      setJsonLoading(false);
      setAlertMessage("Please enter content");
      setIsOpen(!isOpen);
    }
  };
  const handleKeyDownJsonValue = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      verifyJsonValue2();
    }
  };
  const handleTextJson = (event: any) => {
    const newText = event.target.value;
    setJsonValue(newText);
  };
  const handleDropFile = (event: any) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    const fileType = file.type;
    if (fileType !== "application/json") {
      setAlertMessage("Invalid file type! Only JSON files are allowed.");
      setIsOpen(!isOpen);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setJsonValue(event.target?.result);
    };
    reader.readAsText(file);
  };
  return (
    <Box className={classes.content}>
      <Box
        className={classes.inputAreaItem}
        hidden={showTextInputValue !== 3}
        onDrop={handleDropFile}
      >
        <TextField
          value={jsonValue}
          onKeyDown={handleKeyDownJsonValue}
          multiline
          fullWidth
          rows={13}
          onChange={handleTextJson}
          placeholder="Input UTStamp Chain or drag the UTStamp chain file for verification"
          color="info"
          className={classes.textBox}
        />
        <Box className={classes.btnBox} style={{}}>
          <TextField
            size="small"
            disabled
            multiline
            fullWidth
            placeholder=""
            color="info"
            className={classes.NotesBox}
          />
          <SuperButton
            loading={jsonLoading}
            value="Query"
            handleClick={verifyJsonValue2}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
              margin: "0 0 0 20px",
            }}
          />
        </Box>
      </Box>
      {/* json验证成功 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 22}>
        <Box className={classes.selectBox}>
          <img
            src={successful}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>Verification successful</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(3)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
            }}
          />
        </Box>
      </Box>
      {/* json验证失败 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 23}>
        <Box className={classes.selectBox}>
          <img
            src={failed}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>json validation failed</p>
          <SuperButton
            loading={false}
            value="Retry"
            handleClick={() => setShowTextInputValue(3)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
            }}
          />
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={3000}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default ChainArea;

import { useState } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "../../images/uploadFiles2/ico.svg";
import successful from "../../images/uploadFiles2/ico-successful.svg";
import failed from "../../images/uploadFiles2/ico-failed.svg";
import wait from "../../images/uploadFiles2/wait.svg";
import networkError from "../../images/uploadFiles2/networkerror.svg";
import prompt from "../../images/uploadFiles2/ico-prompt.svg";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import DataDisplay from "./DataDisplay";
import { getStoredAccessToken } from "../../utils/localStorage";
import { handleSubmit, handleQueryText } from "./js/Home.service";
import Dialog from "./Dialog";
import "./css/home.css";
import { changeTime } from "../../utils/changeTime";
import SuperButton from "../../components/Button/SuperButton";
import SeletedLevel from "../../components/seletedLevel/SeletedLevel";
const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
  },
  inputAreaItem: {
    width: "100%",
    height: "100%",
  },
  textBox: {
    "& .css-x8jvdr-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-z8tdsz": {
      borderRadius: "12px",
    },
  },
  btnBox: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
  },
  NotesBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
  },
  selectBox: {
    width: "100%",
    height: "340px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
  },
}));

const TextArea = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isLogIn = getStoredAccessToken();
  const [showTextInputValue, setShowTextInputValue] = useState<number>(0);
  const [textValue, setTextValue] = useState("");
  const [textNotes, setTextNotes] = useState<string>("");
  const [queryLoading, setQueryLoading] = useState<boolean>(false);
  const [textWaitMessage, setTextWaitMessage] = useState<string>("");
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [textSubmissionHash, setTextSubmissionHash] = useState<string>("");
  const [submitRecord, setSubmitRecord] = useState<any>();
  const [queryTime, setQueryTime] = useState<any>();
  const [alertMessage, setAlertMessage] = useState("");
  const [level, setLevel] = useState<number>(5);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const handleKeyDownTextValue = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      handleQueryText2();
    }
  };
  const handleQueryValue = (queryStatus: number) => {
    if (queryStatus) {
      if (queryStatus === 200) {
        setShowTextInputValue(4);
      } else if (queryStatus === 404) {
        setShowTextInputValue(5);
      } else if (queryStatus === 408) {
        setShowTextInputValue(6);
      } else if (queryStatus === 500) {
        setShowTextInputValue(7);
      } else {
        setAlertMessage("error");
        setSeverity("error");
        setIsOpen(!isOpen);
      }
    }
  };
  const handleTextValue = (event: any) => {
    const newText = event.target.value;
    setTextValue(newText);
  };
  const handleTextNoteValue = (event: any) => {
    const newText = event.target.value;
    setTextNotes(newText);
  };
  const handleQueryMsg = async (Content: string) => {
    setQueryLoading(true);
    const response = await handleQueryText(Content);
    const queryStatus = response?.status;
    const successTime = response?.data?.timestamp;
    const time = changeTime(successTime);
    if (queryStatus === 408) {
      setTextWaitMessage(response.data.message);
    }
    setTextSubmissionHash(response?.data?.submission_hash);
    setSubmitRecord(JSON.stringify(response?.data));
    setQueryTime(time);
    handleQueryValue(queryStatus);
    setQueryLoading(false);
  };
  const handleQueryText2 = () => {
    if (textValue) {
      handleQueryMsg(textValue);
    } else {
      setAlertMessage("Please enter content");
      setIsOpen(!isOpen);
    }
  };
  const handleRestartSubmit = () => {
    setShowTextInputValue(0);
    setTextValue("");
    setTextNotes("");
  };
  const handelSubmitText = async () => {
    if (!isLogIn) {
      navigate("/login");
      return;
    }
    setSubmitLoading(true);
    const response = await handleSubmit(textValue, textNotes, level);
    const isSubmitSuccess = response?.data?.all_success;
    if (isSubmitSuccess) {
      setShowTextInputValue(8);
      setSubmitLoading(false);
    } else {
      setErrorMessage(response?.data?.message);
      setShowTextInputValue(9);
      setSubmitLoading(false);
    }
  };
  return (
    <Box className={classes.content}>
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 0}>
        <TextField
          value={textValue}
          onKeyDown={handleKeyDownTextValue}
          multiline
          fullWidth
          rows={13}
          onChange={handleTextValue}
          placeholder="Type some text to stamp. Please keep in mind that whitespace and newlines count as part of the data.The text itself will not be stored or sent to our server"
          color="info"
          className={classes.textBox}
        />
        <Box className={classes.btnBox}>
          <TextField
            size="small"
            value={textNotes}
            multiline
            fullWidth
            onChange={handleTextNoteValue}
            placeholder="Notes (Optional)"
            color="info"
            className={classes.NotesBox}
          />
          <SuperButton
            loading={queryLoading}
            value="Query"
            handleClick={handleQueryText2}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
              margin: "0 0 0 20px",
            }}
          />
        </Box>
      </Box>
      {/* 文本已盖章区域 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 4}>
        {/* 200已盖章 */}
        <Box className={classes.selectBox}>
          <img
            src={prompt}
            alt="Icon"
            style={{ width: "32px", height: "32px", position: "relative" }}
          />
          <p>
            The text has been stamped at {queryTime}.<br /> Please click
            'Restart' for a new query, or select 'Return' to revisit the
            existing query.
          </p>
          <DataDisplay
            value={submitRecord}
            time={queryTime}
            submissionHashValue={textSubmissionHash}
            note={textNotes}
          />
          <Box>
            <SuperButton
              loading={false}
              value="Restart"
              handleClick={handleRestartSubmit}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 20px 0 0",
              }}
            />
            <SuperButton
              loading={false}
              value="Return"
              handleClick={() => setShowTextInputValue(0)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* 文本未盖章区域 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 5}>
        <Box className={classes.selectBox}>
          <img
            src={Icon}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p style={{ marginBottom: "0" }}>
            This text is not stamped, do you want to stamp it now?
          </p>
          <SeletedLevel
            handleValue={(value: any) => {
              setLevel(value?.levelIndex);
            }}
          />
          <Box style={{ display: "flex" }}>
            <SuperButton
              loading={submitLoading}
              value="Stamp It!"
              handleClick={handelSubmitText}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 20px 0 0",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => setShowTextInputValue(0)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
          </Box>
          <Dialog textValue={textValue} textNotes={textNotes} />
        </Box>
      </Box>
      {/* 文本等一等区域 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 6}>
        <Box className={classes.selectBox}>
          <img
            src={wait}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          {/* <p>This text message was timestamped at {textWaitTime}, please check back later.</p> */}
          <p>{textWaitMessage}</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(0)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
      </Box>
      {/* 文本网络错误区域 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 7}>
        <Box className={classes.selectBox}>
          <img
            src={networkError}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>Network Error</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(0)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
      </Box>
      {/* 文本盖章成功 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 8}>
        <Box className={classes.selectBox}>
          <img
            src={successful}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>This text is stamped successfully!</p>
          <SuperButton
            loading={false}
            value="Restart"
            handleClick={handleRestartSubmit}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
          <Dialog textValue={textValue} textNotes={textNotes} />
        </Box>
      </Box>
      {/* 文本盖章失败*/}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 9}>
        <Box className={classes.selectBox}>
          <img
            src={failed}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>This text is stamped unsuccessfully!</p>
          {errorMessage && <p style={{ marginTop: "0" }}>{errorMessage}</p>}
          <Box style={{ display: "flex" }}>
            <SuperButton
              loading={false}
              value="Stamp again!"
              handleClick={() => setShowTextInputValue(0)}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 20px 0 0",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => setShowTextInputValue(0)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={3000}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default TextArea;

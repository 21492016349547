import React from "react";
import { Theme, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import List from "./List";
import Charges from "./Charges";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  content: {
    maxWidth: "70%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(1800)]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down(1600)]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  cardBox: {
    width: "100%",
  },
  charges:{
    marginTop:"50px",
    width:"100%",
  }
}));

const Plans: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.content}>
          <Box className={classes.cardBox}>
            <List />
          </Box>
          <Box className={classes.charges}>
            <Charges/>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Plans;

import { useState } from "react";
import {
  Box,
  Theme,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import InputBox from "../../../components/InputBox/InputBox";
import { importKey } from "../js/portal.service";
import SnackbarMessage from "../../../components/Snackbar/Snackbar";
import SuperButton from "../../../components/Button/SuperButton";

const useStyles = makeStyles((theme: Theme) => ({
  BackIcon: {
    cursor: "pointer",
  },
  dialogBox: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  title: {
    fontFamily: "Arial",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    color: "#000000",
  },
  inputBox: {
    display: "flex",
    height: "120px",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
  },
  titleText: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "900",
    display: "inline-block",
    marginBottom: "14px",
  },
}));

interface importKeyProps {
  open: boolean;
  handleClose: () => void;
  handleRefresh: () => void;
}

const ImportKeyDialog = (props: importKeyProps) => {
  const classes = useStyles();
  const { open, handleClose, handleRefresh } = props;
  const [keyValue, setKeyValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");
  const handleImportKey = async () => {
    if (!keyValue) {
      setAlertMessage("Please enter the key");
      setIsOpen(!isOpen);
      setSeverity("info");
      return;
    }
    setLoading(true);
    const res = await importKey(keyValue);
    if (res?.status === 200 && res?.data === "success") {
      setAlertMessage("Import key successfully");
      setIsOpen(!isOpen);
      setSeverity("success");
      handleClose();
      handleRefresh();
    } else {
      setAlertMessage("Import key failed");
      setIsOpen(!isOpen);
      setSeverity("error");
    }
    setLoading(false);
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        className={classes.dialogBox}
        maxWidth="lg"
      >
        <DialogTitle>
          <span className={classes.title}>Import Key</span>
          <CloseIcon
            onClick={() => handleClose()}
            className={classes.BackIcon}
          />
        </DialogTitle>
        <DialogContent>
          <Box style={{ minWidth: "30vw", minHeight: "8vh" }}>
            <Divider />
            <Box className={classes.inputBox}>
              <span className={classes.titleText}>Value:</span>
              <InputBox
                tipText="Please enter the key"
                type="text"
                handelValue={(value: any) => {
                  setKeyValue(value as string);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              padding: "0 22px 20px",
            }}
          >
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => {
                handleClose();
                setKeyValue("");
              }}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 15px 0 0"
              }}
            />
            <SuperButton
              loading={loading}
              value="Submit"
              handleClick={() => handleImportKey()}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
          </Box>
        </DialogActions>
      </Dialog>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={3000}
        isOpen={isOpen}
      />
    </>
  );
};

export default ImportKeyDialog;

import React, { useRef } from 'react';
import { makeStyles } from '@mui/styles'
import { Theme } from "@mui/material";
import Resizer from "react-image-file-resizer";

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        fontWeight: '500',
        fontSize: '14px',
        textAlign: 'center',
        color: '#307DCF',
        marginTop: "10px",
        cursor: "pointer",
    },
}))
interface ImageCropProps {
    handleBase: (base: string) => void
}
const ImageCrop = (props: ImageCropProps) => {
    const { handleBase } = props
    const classes = useStyles()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const compressionImage = (file: any) =>{
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                300,
                300,
                "JPEG",
                100,
                0,
                (uri: any) => {
                    resolve(uri);
                },
                "base64",
                200,
                200
            );
        })
    }
    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (!file) return;
        const base64String = await compressionImage(file)
        handleBase(base64String as string)
    };
    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/*"
                onChange={handleFileInputChange}
            />
            <span className={classes.text} onClick={handleButtonClick}>Change Avatar</span>
            {/* {!!imgSrc && (
                <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                >
                    <img
                        ref={imgRef}
                        alt="Crop me"
                        src={imgSrc}
                        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                        onLoad={onImageLoad}
                    />
                </ReactCrop>
            )} */}
        </>
    )
};

export default ImageCrop;

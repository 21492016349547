import axios, { AxiosResponse } from "axios";
import { axiosApi } from "../../../utils/api";
import { ApiConfig } from "../../../utils/apiConfig";

export interface RequestResponse {
  result: {
    result_code: number;
    message: string;
  };
}
export const handleQueryText = async (
  Content: string
): Promise<AxiosResponse> => {
  try {
    const encodedContent = encodeURIComponent(Content);
    const response = await axios.get(
      `${
        ApiConfig().REACT_APP_API_URL
      }/submission/query?content=${encodedContent}`
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const handleQueryHash = async (Hash: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      `${ApiConfig().REACT_APP_API_URL}/submission/query?hash=${Hash}`
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const handleSubmit = async (
  Content?: string,
  Notes?: string,
  priority?: number
): Promise<AxiosResponse> => {
  try {
    const params = {
      entries: [
        {
          Content,
          Notes,
          priority
        },
      ],
    };
    const response = await axiosApi().post("/submissions", params);
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const handleSubmitHash = async (
  Hash: string,
  Notes?: string,
  priority?: number
): Promise<AxiosResponse> => {
  try {
    const params = {
      entries: [
        {
          Hash,
          Notes,
          priority
        },
      ],
    };
    const response = await axiosApi().post("/submissions", params);
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const getSecretPhraseURL = async (
  language: string,
  words: string
): Promise<AxiosResponse> => {
  try {
    const params = {
      language: language,
      words: words,
    };
    const response = await axios.post(
      `${ApiConfig().REACT_APP_API_URL}/block/mnemonic`,
      params
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const getTimestampURL = async (
  hashValue: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${ApiConfig().REACT_APP_API_URL}/block/${hashValue}`
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

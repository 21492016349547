import { useState } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Icon from "../../images/uploadFiles2/ico.svg";
import successful from "../../images/uploadFiles2/ico-successful.svg";
import failed from "../../images/uploadFiles2/ico-failed.svg";
import wait from "../../images/uploadFiles2/wait.svg";
import networkError from "../../images/uploadFiles2/networkerror.svg";
import prompt from "../../images/uploadFiles2/ico-prompt.svg";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import DataDisplay from "./DataDisplay";
import { getStoredAccessToken } from "../../utils/localStorage";
import { handleSubmitHash, handleQueryHash } from "./js/Home.service";
import Dialog from "./Dialog";
import "./css/home.css";
import { changeTime } from "../../utils/changeTime";
import SuperButton from "../../components/Button/SuperButton";
import SeletedLevel from "../../components/seletedLevel/SeletedLevel";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
  },
  inputAreaItem: {
    width: "100%",
    height: "100%",
  },
  selectBox: {
    width: "100%",
    height: "340px",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      textAlign: "center",
    },
  },
  textBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
  },
  btnBox: {
    display: "flex",
    marginTop: "10px",
    alignItems: "center",
  },
  NotesBox: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
  },
}));

const HashArea = () => {
  const classes = useStyles();
  const regexExp = /^[a-f0-9]{64}$/gi;
  const navigate = useNavigate();
  const isLogIn = getStoredAccessToken();
  const [showTextInputValue, setShowTextInputValue] = useState<number>(2);
  const [alertMessage, setAlertMessage] = useState("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hashValue, setHashValue] = useState<any>();
  const [queryHashLoading, setQueryHashLoading] = useState<boolean>(false);
  const [submitHashLoading, setSubmitHashLoading] = useState<boolean>(false);
  const [hashNotes, setHashNotes] = useState<string>("");
  const [hashSubmitRecord, setHashSubmitRecord] = useState<any>();
  const [hashQueryTime, setHashQueryTime] = useState<any>();
  const [submissionHash, setSubmissionHash] = useState<string>("");
  const [level, setLevel] = useState<number>(5);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [hashWaitMessage, setHashWaitMessage] = useState<string>("");
  const [severity, setSeverity] = useState<
    "error" | "warning" | "info" | "success"
  >("info");

  const handleQueryHashValue = (queryStatus: number) => {
    if (queryStatus) {
      if (queryStatus === 200) {
        setShowTextInputValue(16);
      } else if (queryStatus === 404) {
        setShowTextInputValue(17);
      } else if (queryStatus === 408) {
        setShowTextInputValue(18);
      } else if (queryStatus === 500) {
        setShowTextInputValue(19);
      } else {
        setAlertMessage("error");
        setIsOpen(!isOpen);
      }
    }
  };

  const handelSubmitHash = async () => {
    if (!isLogIn) {
      navigate("/login");
      return;
    }
    setSubmitHashLoading(true);
    const response = await handleSubmitHash(hashValue, hashNotes, level);
    const isSubmitSuccess = response?.data?.all_success;
    if (isSubmitSuccess) {
      setShowTextInputValue(20);
      setSubmitHashLoading(false);
    } else {
      setErrorMessage(response?.data?.message);
      setShowTextInputValue(21);
      setSubmitHashLoading(false);
    }
  };
  const handleKeyDownHashValue = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      queryHash();
    }
  };
  const handleHashValue = (event: any) => {
    const newData = event.target.value;
    setHashValue(newData);
  };
  const handleHashNoteValue = (event: any) => {
    const newText = event.target.value;
    setHashNotes(newText);
  };
  const queryHashValue = async (hashValue: string) => {
    setQueryHashLoading(true);
    const response = await handleQueryHash(hashValue);
    const queryStatus = response?.status;
    const successTime = response?.data?.timestamp;
    const time = changeTime(successTime);
    if (queryStatus === 408) {
      setHashWaitMessage(response.data.message);
    }
    setHashSubmitRecord(JSON.stringify(response?.data));
    setHashQueryTime(time);
    handleQueryHashValue(queryStatus);
    setQueryHashLoading(false);
    setSubmissionHash(response?.data?.submission_hash);
  };
  const queryHash = async () => {
    if (hashValue !== "") {
      const isHash = regexExp.test(hashValue);
      if (isHash) {
        queryHashValue(hashValue);
      } else {
        // setHashValue('')
        setAlertMessage("Please enter the correct hash value");
        setSeverity("info");
        setIsOpen(!isOpen);
      }
    } else {
      setAlertMessage("Please enter content");
      setIsOpen(!isOpen);
    }
  };
  const handleHashRestart = () => {
    setHashValue("");
    setHashNotes("");
    setShowTextInputValue(2);
  };
  return (
    <Box className={classes.content}>
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 2}>
        <TextField
          value={hashValue}
          onKeyDown={handleKeyDownHashValue}
          multiline
          fullWidth
          rows={13}
          onChange={handleHashValue}
          placeholder="Type some hash to stamp."
          color="info"
          className={classes.textBox}
        />
        <Box className={classes.btnBox} style={{}}>
          <TextField
            size="small"
            value={hashNotes}
            multiline
            fullWidth
            onChange={handleHashNoteValue}
            placeholder="Notes (Optional)"
            color="info"
            className={classes.NotesBox}
          />
          <SuperButton
            loading={queryHashLoading}
            value="Query"
            handleClick={queryHash}
            UIType="confirm"
            option={{
              width: "120px",
              height: "40px",
              margin: "0 0 0 20px",
            }}
          />
        </Box>
      </Box>
      {/* hash已盖章 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 16}>
        <Box className={classes.selectBox}>
          <img
            src={prompt}
            alt="Icon"
            style={{ width: "32px", height: "32px", position: "relative" }}
          />
          <p>
            The hash value has been stamped at {hashQueryTime}.<br /> Please
            click 'Restart' for a new query, or select 'Return' to revisit the
            existing query.
          </p>
          <DataDisplay
            value={hashSubmitRecord}
            time={hashQueryTime}
            submissionHashValue={submissionHash}
            note={hashNotes}
          />
          <Box>
            <SuperButton
              loading={false}
              value="Restart"
              handleClick={handleHashRestart}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            <SuperButton
              loading={queryHashLoading}
              value="Return"
              handleClick={() => setShowTextInputValue(2)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 0 0 20px",
              }}
            />
          </Box>
        </Box>
      </Box>
      {/* hash未盖章 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 17}>
        <Box className={classes.selectBox}>
          <img
            src={Icon}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p style={{ marginBottom: "0" }}>
            This hash is not stamped, do you want to stamp it now?
          </p>
          <SeletedLevel
            handleValue={(value: any) => {
              setLevel(value?.levelIndex);
            }}
          />
          <Box style={{ display: "flex" }}>
            <SuperButton
              loading={submitHashLoading}
              value="Stamp It!"
              handleClick={handelSubmitHash}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => setShowTextInputValue(2)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 0 0 20px",
              }}
            />
          </Box>
          <Dialog hashValue={hashValue} textNotes={hashNotes} />
        </Box>
      </Box>
      {/* hash等一等 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 18}>
        <Box className={classes.selectBox}>
          <img
            src={wait}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          {/* <p>This hash message was timestamped at {hashWaitTime}, please check back later.</p> */}
          <p>{hashWaitMessage}</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(2)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
      </Box>
      {/* hash网络错误 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 19}>
        <Box className={classes.selectBox}>
          <img
            src={networkError}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>Network Error</p>
          <SuperButton
            loading={false}
            value="OK"
            handleClick={() => setShowTextInputValue(2)}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
        </Box>
      </Box>
      {/*  hash盖章成功 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 20}>
        <Box className={classes.selectBox}>
          <img
            src={successful}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>This hash is stamped successfully!</p>
          <SuperButton
            loading={false}
            value="Restart"
            handleClick={handleHashRestart}
            UIType="confirm"
            option={{
              width: "120px",
              height: "44px",
            }}
          />
          <Dialog hashValue={hashValue} textNotes={hashNotes} />
        </Box>
      </Box>
      {/* hash盖章失败 */}
      <Box className={classes.inputAreaItem} hidden={showTextInputValue !== 21}>
        <Box className={classes.selectBox}>
          <img
            src={failed}
            alt="Icon"
            style={{ width: "32px", height: "32px" }}
          />
          <p>This hash is stamped unsuccessfully!</p>
          {errorMessage && <p style={{ marginTop: "0" }}>{errorMessage}</p>}
          <Box style={{ display: "flex" }}>
            <SuperButton
              loading={false}
              value="Stamp It!"
              handleClick={() => setShowTextInputValue(2)}
              UIType="confirm"
              option={{
                width: "120px",
                height: "44px",
              }}
            />
            <SuperButton
              loading={false}
              value="Cancel"
              handleClick={() => setShowTextInputValue(2)}
              UIType="cancel"
              option={{
                width: "120px",
                height: "44px",
                margin: "0 0 0 20px",
              }}
            />
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={severity}
        duration={3000}
        isOpen={isOpen}
      />
    </Box>
  );
};

export default HashArea;

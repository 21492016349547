import axios from "axios";
import { ApiConfig } from "../../utils/apiConfig";

export const getBackendVersion = async () => {
  try {
    const response = await axios.get(
      `${ApiConfig().REACT_APP_API_URL}/version`
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

import React, { useState, useEffect } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import logo from "../../images/shared/Logo.svg";
import { TextField } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UserPool from "../../cognito/userpool";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMessage from "../../components/Snackbar/Snackbar";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { storeValue } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setUsername, setEmail } from "../../redux/actions/actions";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  logo: {
    margin: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "10px",
      textAlign: "center",
    },
  },
  content: {
    width: "400px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.02)",
    borderRadius: "12px",
    margin: "5vh auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px 40px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto 5vh",
    },
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#000000",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0",
    },
  },
  loginWay: {
    width: "100%",
    height: "44px",
    background: "#F9F9F9",
    border: "1px solid #E6E6E6",
    borderRadius: "24px",
    margin: "8px 0",
  },
  inputStyle: {
    width: "100%",
    height: "44px",
    margin: "8px 0 !important",
    "& .MuiInputBase-root": {
      borderRadius: "24px",
      fontSize: "14px",
    },
    "& .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "12px 14px",
    },
    "& .css-1o6z5ng": {
      padding: "12px 14px",
    },
  },
  signUpBtn: {
    width: "100%",
    height: "44px",
    background: "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%) !important",
    boxShadow: "0px 2px 5px rgba(48, 125, 207, 0.3) !important",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
  signUpInfo: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "center",
  },
  backBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  confirmBtn: {
    width: "100%",
    height: "44px",
    borderRadius: "30px !important",
    color: "#FFFFFF !important",
    marginTop: "13px !important",
  },
}));

const Confirm: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  //   const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  // const username: string = urlParams.get('username')!
  //通过state接受
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const info = location.state;

  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [userNameValue, setUserNameValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<any>();
  useEffect(() => {
    if (info) {
      setUserNameValue(info?.username);
      setPasswordValue(info?.password);
    }
  }, [info]);
  const login = () => {
    if (!userNameValue || !passwordValue) {
      setAlertMessage("Please input username and password");
      setIsOpen(!isOpen);
      setLoading(false);
      return;
    }
    const cognitoUser = new CognitoUser({
      Username: userNameValue.trim(),
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: userNameValue.trim(),
      Password: passwordValue.trim(),
    });
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: (data: any) => {
        // const userId: string = data.accessToken?.payload?.username;
        const idToken: string = data.idToken.jwtToken;
        const accessToken: string = data.accessToken.jwtToken;
        const refreshToken: string = data.refreshToken.token;
        const user_name: string = data.accessToken.payload.username;
        const email: string = data.idToken.payload.email;
        const cognito_groups = data.accessToken.payload["cognito:groups"]
          ? data.accessToken.payload["cognito:groups"]
          : undefined;
        storeValue("UTStamp_access_token", accessToken);
        storeValue("UTStamp_id_token", idToken);
        storeValue("refresh_token", refreshToken);
        storeValue("cognito_groups", JSON.stringify(cognito_groups));
        //存储到store中
        dispatch(setUsername(user_name));
        dispatch(setEmail(email));
        storeValue("loginMethod", "email");
        navigate("/");
        setLoading(false);
      },
      onFailure: (err: any) => {
        if (err.message === "User is not confirmed.") {
          navigate(`/confirm?username=${userNameValue}`);
        }
        setAlertMessage("Incorrect username or password.");
        setIsOpen(!isOpen);
        console.log("onFail", err.message);
        setLoading(false);
      },
    });
  };
  const confirm = async () => {
    if (!userNameValue || !verificationCode) {
      setAlertMessage("Please input username and verification code");
      setIsOpen(!isOpen);
      return;
    }
    setLoading(true);
    const confirmation_code = verificationCode.trim();
    const user = new CognitoUser({
      Username: userNameValue.trim(),
      Pool: UserPool,
    });
    user.confirmRegistration(confirmation_code, true, (err, result) => {
      if (err) {
        console.log(err);
        setAlertMessage(err.message);
        setIsOpen(!isOpen);
        setLoading(false);
      } else {
        console.log(result);
        // setLoading(false)
        // navigate(`/login?username=${userNameValue}`)
        login();
      }
    });
  };
  const confirmKeyDown = (e: any) => {
    if (e.key === "Enter") {
      confirm();
    }
  };
  return (
    <>
      <Box
        className={classes.root}
        style={{
          pointerEvents: loading ? "none" : "auto",
        }}
      >
        <Box className={classes.logo}>
          <img src={logo} alt="network error" />
        </Box>
        <Box className="home">
          <Box className={classes.content}>
            <Box className={classes.backBox}>
              {/* <ArrowBackIcon
                onClick={() => navigate("/signUp")}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              /> */}
              <Box className={classes.title}>Confirm Register</Box>
              {/* <div style={{ width: "24px", height: "24px" }} /> */}
            </Box>
            <TextField
              onKeyDown={confirmKeyDown}
              size="small"
              placeholder="Username"
              value={userNameValue}
              onChange={(e) => setUserNameValue(e.target.value)}
              className={classes.inputStyle}
            />
            <TextField
              onKeyDown={confirmKeyDown}
              size="small"
              placeholder="Confirmation code"
              onChange={(e) => setVerificationCode(e.target.value)}
              className={classes.inputStyle}
            />
            <LoadingButton
              variant="contained"
              loading={loading}
              onClick={confirm}
              className={classes.confirmBtn}
              sx={{
                background: loading
                  ? "#FFFFFF"
                  : "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%)",
                textTransform: "none",
                boxShadow: loading
                  ? "none"
                  : "0px 2px 5px rgba(48, 125, 207, 0.3) !important",
              }}
            >
              {!loading && "Confirm"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
      <SnackbarMessage
        message={alertMessage}
        severity={"info"}
        duration={1000}
        isOpen={isOpen}
      />
    </>
  );
};
export default Confirm;

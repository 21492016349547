import * as d3 from "d3";
import React from "react";
import { drawTree, ComplementPathElement } from "../../utils/drawTree";
interface TreeVizProps {
  path: ComplementPathElement[];
  submissionHash: string;
  markleRoot: string;
}
const TreeViz = (props: TreeVizProps) => {

  const ref: React.RefObject<HTMLDivElement> = React.createRef();

  const isMobile = () => {
    return window.innerWidth < 768;
  };

  const boxHight = ():string => {
    if(props.path.length <= 0) return "80px";
    const res = isMobile() ? `${180 + (props.path.length - 2) * 30}px` : `${360 + (props.path.length - 2) * 60}px`;
    return res;
  }

  React.useEffect(() => {
    drawTree(
      props.path,
      props.submissionHash,
      props.markleRoot,
      d3.select(ref.current).select("svg")
    );
  });

  if (props.path.length <= 0) {
    return <div></div>;
  }

  return (
    <div
      className="TreeViz"
      style={{
        width: "100%",
        border:"1px solid #D9D9D9"
      }}
      ref={ref}
    >
      <svg
        style={{
          display: "inline-block",
          width: "100%",
          height: boxHight()
        }}
      />
    </div>
  );
};

export default TreeViz;

// function base64ToUint8Array(base64String: any) {
//     const padding = '='.repeat((4 - base64String.length % 4) % 4);
//     const base64 = (base64String + padding)
//         .replace(/-/g, '+')
//         .replace(/_/g, '/');

//     const rawData = window.atob(base64);
//     const outputArray = new Uint8Array(rawData.length);

//     for (let i = 0; i < rawData.length; ++i) {
//         outputArray[i] = rawData.charCodeAt(i);
//     }
//     return outputArray;
// }

// function base64ToHexString(base64String: any) {
//     const array = base64ToUint8Array(base64String)
//     return array.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '')
// }
function hexOf(array: any) {
    return array.reduce((str: any, byte: any) => str + byte.toString(16).padStart(2, '0'), '')
}
async function hash(v1: any, v2: any) {
    const left = hexToUint8Array(v1)
    const right = hexToUint8Array(v2)
    const msgUnit8 = new Uint8Array(left.byteLength + right.byteLength)
    msgUnit8.set(left, 0)
    msgUnit8.set(right, left.byteLength)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUnit8)
    return hexOf(new Uint8Array(hashBuffer))
}
function hexToUint8Array(hexString:string) {
    const byteArray = new Uint8Array(hexString.length / 2)
    for (let i = 0; i < hexString.length; i += 2) {
        byteArray[i / 2] = parseInt(hexString.substring(i, i + 2), 16)
    }
    return byteArray
}
export const verifyChain = async (jsonValue: any) => {
    const content = JSON.parse(jsonValue)
    let txHexData = content.bitcoin_stamp?.data
    let rootKey = content.merkle_root
    let hashKey = content.submission_hash
    let current = content.complement_path
    if (!txHexData || !rootKey || !hashKey || !current) {
        return 2
    }
    if (rootKey === hashKey && txHexData === hashKey) {
        return 1
    }
    for (const node of current) {
        let left = node.position === 'RIGHT' ? hashKey : node.hash
        let right = node.position === 'RIGHT' ? node.hash : hashKey
        hashKey = await hash(left, right)
    }
    if (rootKey !== hashKey || txHexData !== hashKey) {
        console.log('rootKey', rootKey)
        console.log('hashKey', hashKey)
        console.log('txHexData', txHexData)
        return 2
    } else {
        return 1
    }
}
import { useEffect } from "react";
import { Box, type Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "0 20px"
    }
  },
  item: {
    marginBottom: "30px",
  },
  itemTitle: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  itemText: {
    color: "#555",
    fontFamily: "Avenir",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  table: {
    borderRadius: "12px !important",
    boxShadow:"0px 4px 10px 0px rgba(0, 0, 0, 0.02)",
    "& .MuiTableCell-root": {
      height: "50px",
      maxWidth: "50px",
      borderBottom: "none",
    },
    "& .MuiTableHead-root": {
      "& .MuiTableCell-root": {
        color: "#555",
        fontFamily: "Avenir",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "900",
        lineHeight: "16px",
        borderBottom: "1px solid #E0E0E0",
      },
    },
  },
}));

const Charges = () => {
  const classes = useStyles();
  const list = [
    {
      item: "Tokens",
      P0: "72",
      P1: "24",
      P2: "12",
      P3: "6",
      P4: "3",
      P5: "1",
    },
    {
      item: "Expect Delay (mins)",
      P0: "20",
      P1: "60",
      P2: "120",
      P3: "240",
      P4: "480",
      P5: "1440",
    },
  ];
  useEffect(() => {
    console.log("list", list);
  });
  return (
    <>
      <Box className={classes.content}>
        <Box className={classes.item}>
          <p className={classes.itemTitle}>Choosing Your Plan</p>
          <span className={classes.itemText}>
            You can upgrade your active token's limits by paying for a premium
            plan. We offer one-time purchase via a credit card payment.
          </span>
        </Box>
        <Box className={classes.item}>
          <p className={classes.itemTitle}>Submission Priority Config Table</p>
          <TableContainer
            component={Paper}
            className={classes.table}
            sx={{ boxShadow: "none" }}
          >
            <Table size="small" stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Levels</TableCell>
                  <TableCell align="left">P0</TableCell>
                  <TableCell align="left">P1</TableCell>
                  <TableCell align="left">P2</TableCell>
                  <TableCell align="left">P3</TableCell>
                  <TableCell align="left">P4</TableCell>
                  <TableCell align="left">P5</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="left"
                      style={{
                        color: "#555",
                        fontFamily: "Avenir",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "900",
                        lineHeight: "16px",
                      }}
                    >
                      {row.item}
                    </TableCell>
                    <TableCell align="left">{row.P0}</TableCell>
                    <TableCell align="left">{row.P1}</TableCell>
                    <TableCell align="left">{row.P2}</TableCell>
                    <TableCell align="left">{row.P3}</TableCell>
                    <TableCell align="left">{row.P4}</TableCell>
                    <TableCell align="left">{row.P5}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box className={classes.item}>
          <p className={classes.itemTitle}>Notes:</p>
          <ol>
            <li className={classes.itemText}>
              P0 is the highest level, P5 is the lowest level.
            </li>
            <li className={classes.itemText}>
              Tokens are the tokens consumed for each submission, higher levels
              consume more tokens.
            </li>
            <li className={classes.itemText}>
              Expect delay is an approximate time, not an exact time.
            </li>
          </ol>
        </Box>
      </Box>
    </>
  );
};

export default Charges;

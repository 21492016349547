import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import Axios, { AxiosInstance } from 'axios';

export const storeValue = (key: string, token: string) => {
    localStorage.setItem(key, token)
}

export const getStoredValue = (key: string): string | null => {
    const token = localStorage.getItem(key)
    return token
}

export const addUserAttribute = (name: string, value: string): AmazonCognitoIdentity.CognitoUserAttribute => {
    const attribute = new AmazonCognitoIdentity.CognitoUserAttribute({ Name: name, Value: value })
    return attribute
}
export const removeStoredValue = (key: string) => {
    localStorage.removeItem(key)
}
export const getStoredRefreshToken = (): string | null => {
    const token = localStorage.getItem('refresh_token')
    return token
}
//清空
export const clearStoredValue = () => {
    localStorage.clear()
}
export const axiosCognitoApi = (): AxiosInstance =>
    Axios.create({
        baseURL: "https://cognito-idp.us-west-2.amazonaws.com",
        headers: {
            'Content-Type': 'application/x-amz-json-1.1',
        }
    })

export const isNotEmptyString = (str: any): boolean => {
    return str !== null && str !== undefined && str !== "";
};

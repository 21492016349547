import LoadingButton from "@mui/lab/LoadingButton";
interface loadingButton {
  UIType: "confirm" | "view" | "cancel";
  size?: "small" | "normal";
  loading: boolean;
  isShow?: boolean;
  value: string;
  handleClick?: any;
  isDisabled?: boolean;
  isPointerEvents?: "none" | "auto" | "inherit";
  option?: {
    icon?: string;
    width?: string;
    height?: string;
    margin?: string;
    background?: string;
    boxShadow?: string;
    borderRadius?: string;
    color?: string;
    border?: string;
    textTransform?: string;
    fontSize?: string;
    fontWeight?: number;
    backgroundHover?: string;
    colorHover?: string;
  };
}
const SuperButton = (props: loadingButton) => {
  const { UIType, loading, value, handleClick, option, isShow = true, isDisabled, isPointerEvents } = props;

  const backgroundColor = (): string => {
    if(loading){
      return "#fff";
    }
    if (option?.background) {
      return option?.background;
    }
    if (UIType === "confirm") {
      return "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%) !important";
    } else if (UIType === "view") {
      return "#1CB5E5";
    } else if (UIType === "cancel") {
      return "#fff";
    } else {
      return "";
    }
  };

  const color = (): string => {
    if (option?.color) {
      return option?.color;
    }
    if (UIType === "confirm") {
      return "#FFFFFF !important";
    } else if (UIType === "view") {
      return "#FFFFFF";
    } else if (UIType === "cancel") {
        return "#307DCF";
    } else {
      return "";
    }
  };

  const border = (): string => {
    if (option?.border) {
      return option?.border;
    }
    if (UIType === "confirm") {
      return "none";
    } else if (UIType === "view") {
      return "1px solid #1CB5E5";
    } else if (UIType === "cancel") {
      return "1px solid #307DCF";
    } else {
      return "";
    }
  };

  const backgroundHover = (): string => {
    if (option?.backgroundHover) {
      return option?.backgroundHover;
    }
    if (UIType === "confirm") {
      return "linear-gradient(90deg, #1CB5E5 0%, #2884E7 105%) !important";
    } else if (UIType === "view") {
      return "#2884E7";
    } else if (UIType === "cancel") {
      return "#fff";
    } else {
      return "";
    }
  };

  const colorHover = (): string => {
    if (option?.colorHover) {
      return option?.colorHover;
    }
    if (UIType === "confirm") {
      return "#FFFFFF !important";
    } else if (UIType === "view") {
      return "#FFFFFF";
    } else if (UIType === "cancel") {
        return "#307DCF";
    } else {
      return "";
    }
  };
  return (
    <>
      {isShow && (
        <LoadingButton
          disabled={loading || isDisabled}
          variant="outlined"
          loading={loading}
          onClick={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            handleClick();
          }}
          style={{
              cursor: isDisabled ?? false ? "not-allowed" : "pointer",
              pointerEvents: loading ? "none" : "auto",
          }}
          sx={{
            textTransform: "none",
            background: backgroundColor(),
            color: color(),
            border: border(),
            width: option?.width ?? "100%",
            height: option?.height ?? "44px",
            fontSize: option?.fontSize ?? "14px",
            borderRadius: option?.borderRadius ?? "30px",
            boxShadow: option?.boxShadow ?? "none",
            margin: option?.margin ?? "0",
            pointerEvents: isPointerEvents ?? "auto",
            fontWeight: option?.fontWeight ?? 700,
            "&:hover": {
              background: backgroundHover(),
              color: colorHover(),
              border: border(),
            },
          }}
        >
          {!loading && value}
        </LoadingButton>
      )}
    </>
  );
};
export default SuperButton;

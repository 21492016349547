import { useState, useEffect, useCallback } from "react";
import { Theme, Box, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg_plan from "../../../images/portal/plans/bg-plan.png";
import icon_purchase from "../../../images/portal/plans/ico-purchase.svg";
import { getPlan, payment } from "../js/portal.service";
import LinearProgress from "@mui/material/LinearProgress";
import Skeleton from "@mui/material/Skeleton";
import { getStoredValue } from "../../../utils/utils";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    fontFamily: "Avenir",
    fontStyle: "normal",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  dialogBox: {
    "& .MuiTypography-h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  BackIcon: {
    cursor: "pointer",
  },
  item: {
    width: "280px",
    height: "280px",
    borderRadius: "12px",
    overflow: "hidden",
    marginBottom: "30px",
    background: "#FFFFFF",
    boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.25)",
  },
  itemHeader: {
    width: "100%",
    height: "148px",
    background: `url(${bg_plan})`,
    backgroundSize: "auto",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#FFFFFF",
  },
  itemHeaderTitle: {
    fontWeight: "800",
    fontSize: "24px",
  },
  itemHeaderPrice: {
    fontWeight: "500",
    fontSize: "16px",
  },
  itemDes: {
    width: "100%",
    fontWeight: "400",
    fontSize: "14px",
    color: "#555555",
    padding: "15px",
    textAlign: "center",
  },
  itemBtn: {
    cursor: "pointer",
    width: "100%",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  itemBtnText: {
    fontWeight: "800",
    fontSize: "16px",
    color: "#307DCF",
  },
  inputBox: {
    width: "240px",
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "& .css-3xzbvm-MuiInputBase-root-MuiOutlinedInput-root": {
      borderRadius: "12px",
    },
    "& .css-1x5jdmq": {
      padding: "10.5px 14px",
    },
    "& .css-w878ib": {
      borderRadius: "12px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  quantityBox: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0px",
  },
  priceBox: {
    fontWeight: "400",
    fontSize: "14px",
  },
  quantityTitle: {
    fontWeight: "800",
    fontSize: "14px",
    marginRight: "10px",
  },
  title: {
    fontFamily: "Avenir",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "20px",
    color: "#00000",
    margin: "30px",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "15px",
    },
  },
  btnBox: {
    marginBottom: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "20px",
  },
}));

const List = () => {
  
  const classes = useStyles();
  const [planList, setPlanList] = useState<any>();
  const [getPlanLoading, setGetPlanLoading] = useState<boolean>(false);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [Index, setIndex] = useState<number>();
  const storedAccessToken = getStoredValue("UTStamp_access_token") || "";

  const getPlanList = useCallback(async () => {
    setGetPlanLoading(true);
    const res = await getPlan();
    console.log("plan list res", res);
    if (res === undefined) getPlanList();
    if (res?.status === 200 && res?.data.length > 0) {
      setPlanList(res.data);
    } else {
      setPlanList([]);
    }
    setGetPlanLoading(false);
  }, []);
  const payMent = async (priceId: string, count: number, Index: any) => {
    setIndex(Index);
    setPaymentLoading(true);
    const res = await payment(priceId, count);
    if (!res) {
      setPaymentLoading(false);
      return;
    }
    setPaymentLoading(false);
    if (res.data.message === "success") {
      const url = res.data.url;
      window.open(url, "_self");
    }
  };
  useEffect(() => {
    getPlanList();
  }, [getPlanList, storedAccessToken]);

  return (
    <Box
      style={{
        pointerEvents: paymentLoading ? "none" : "auto",
      }}
    >
      <span className={classes.title}>
        {getPlanLoading ? "loading..." : "Plans"}
      </span>
      {getPlanLoading ? (
        <Box className={classes.content}>
          {[1, 2, 3, 4].map((_item, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              width={280}
              height={280}
              style={{ marginBottom: "30px", borderRadius: "12px" }}
            />
          ))}
        </Box>
      ) : (
        <Box className={classes.content}>
          {planList?.map((item: any, index: number) => {
            return (
              <Box
                className={classes.item}
                key={index}
                style={{
                  opacity: paymentLoading && Index === index ? 0.5 : 1,
                  pointerEvents: paymentLoading ? "none" : "auto",
                }}
              >
                <Box className={classes.itemHeader}>
                  <p className={classes.itemHeaderTitle}>
                    {item.metadata.display_name}
                  </p>
                  <p className={classes.itemHeaderPrice}>
                    $&nbsp;
                    {item.custom_unit_amount
                      ? "?"
                      : (item.unit_amount_decimal / 100).toFixed(2)}
                  </p>
                </Box>
                <Box className={classes.itemDes}>
                  {item.metadata.description}
                  <p style={{ margin: "0" }}>One-time purchase</p>
                </Box>
                {paymentLoading && Index === index ? (
                  <LinearProgress />
                ) : (
                  <Divider />
                )}
                <Box
                  className={classes.itemBtn}
                  onClick={() => {
                    payMent(item.id, item.unit_amount_decimal / 10, index);
                  }}
                >
                  <img
                    src={icon_purchase}
                    alt="icon_purchase"
                    style={{ marginRight: "6px" }}
                  />
                  <span className={classes.itemBtnText}>Purchase</span>
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
export default List;

import React, { useState } from "react";
import { Box, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Plans from "./plans/Plans";
import Submissions from "../portal/submissions/Submissions";
import Tokens from "../portal/ut_tokens/Tokens";
import Profile from "../portal/profile/Profile";
import Menu from "./menu/Menu";
import PortalHeader from "./header/PortalHeader";
import PaySuccessful from "./plans/PaySuccessful";
import PayFiled from "./plans/PayFiled";
import { Routes, Route } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  contentBox: {
    width: "100%",
    display: "flex",
    position: "relative",
  },
  content: {
    width: "100%",
  },
  rightBox: {
    width: "100%",
  },
  menuContent: {
    width: "calc(100% - 280px)",
    position: "absolute",
    top: 60,
    left: 280,
    background: "#F9F9F9",
    minHeight: "calc(100vh - 60px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      left: 0,
    },
  },
}));
const Portal: React.FC = () => {
  const classes = useStyles();
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(true);
  const handleOpenMenu = (isOpen: boolean) => {
    setIsOpenMenu(isOpen);
  };
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.contentBox}>
          <Menu isOpenMenu1={isOpenMenu} />
          <PortalHeader onOpenMenu={handleOpenMenu} />
          <Box className={classes.menuContent}>
            <Routes>
              <Route path="/tokens" element={<Tokens />} />
              <Route path="/submissions" element={<Submissions />} />
              <Route path="/tokens" element={<Tokens />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/plans" element={<Plans />} />
              <Route path="/paysuccessful" element={<PaySuccessful />} />
              <Route path="/payfailed" element={<PayFiled />} />
              <Route path="*" element={<Tokens />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Portal;

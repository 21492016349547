import { axiosApi } from '../../../utils/api'
import { AxiosResponse } from 'axios'
import { ApiConfig } from "../../../utils/apiConfig";

export const getToken = async () => {
    try {
        console.log("log--getToken")
        const response = await axiosApi().get(`/user/license-keys`)
        return response
    } catch (error: any) {
        return error.response;
    };
}

export const getSubmissions = async (startKey: string): Promise<AxiosResponse> => {
    try {
        const params = {
            hash: "",
            limit: 6,
            nextToken: startKey
        }
        const response = await axiosApi().post(`/user/submission`, params)
        return response
    } catch (error: any) {
        return error.response;
    };
}

export const setUserInfo = async (avatar?: string, nickname?: string, username?: string, email?: string) => {
    try {
        const params = {
            avatar,
            nickname,
            username,
            email
        }
        const response = await axiosApi().post(`/user/info`, params)
        return response
    } catch (error: any) {
        return error.response;
    };
}

export const getPlan = async () => {
    try {
        const response = await axiosApi().get(`/plan`)
        return response
    } catch (error: any) {
        return error.response;
    };
}

export const payment = async (priceId: string, count: number) => {
    try {
        const params = {
            "price_id": priceId,
            "success_url": `${ApiConfig().REACT_APP_DOMAIN}/portal/paysuccessful?count=${count}`,
            "cancel_url": `${ApiConfig().REACT_APP_DOMAIN}/portal/plans`,
            // "success_url": `http://localhost:3000/portal/paysuccessful?count=${count}`,
            // "cancel_url": `http://localhost:3000/portal/plans`,
        }
        const response = await axiosApi().post(`/payment/session`, params)
        return response
    } catch (error: any) {
        return error.response;
    };
}

export const viewPaymentState = async (sessionId: string) => {
    try {
        const response = await axiosApi().get(`/payment/session${sessionId}`)
        return response
    } catch (error: any) {
        return error.response;
    };
}

export const importKey = async (key: string) => {
    try {
        const params = {
            key
        }
        const response = await axiosApi().post(`/user/license-key/bind`, params)
        return response
    } catch (error: any) {
        return error.response;
    };
}
import { useEffect } from 'react';
import { Box, Theme, useMediaQuery } from "@mui/material";
import { makeStyles } from '@mui/styles'
import UserInfo from '../../../components/userInfo/UserInfo'
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import web from '../../../images/portal/header/ico-web.svg'
const useStyles = makeStyles((theme: Theme) => ({
    header: {
        width: "100%",
        height: "60px",
        background: "#fff",
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.02)',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        zIndex: 2
    },
    userInfo: {
        display: "flex",
        alignItems: "center",
        marginRight: "30px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "10px",
        }
    },
    menuBtn: {
        marginLeft: "10px",
    },
}))
interface PortalHeaderProps {
    onOpenMenu: (isOpenMenu: boolean) => void
}
const PortalHeader = (props: PortalHeaderProps) => {
    const { onOpenMenu } = props
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles()
    const navigate = useNavigate();
    const openMenu = (e: any) => {
        onOpenMenu(false)
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }
    useEffect(() => {
        document.addEventListener('click', () => { onOpenMenu(true) })
    }, [onOpenMenu])
    return (
        <>
            <Box className={classes.header}>
                <Box>{isMobile &&
                    <MenuIcon
                        className={classes.menuBtn}
                        onClick={openMenu}
                    />
                }
                </Box>
                <Box className={classes.userInfo}>
                    <Box
                        onClick={() => navigate('/')}
                        className='loginBtn'
                        style={{
                            marginRight: "28px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <img src={web} alt="portal" style={{ marginRight: "10px", width: "24px", height: "24px" }} />
                        <span style={{ fontSize: "17px" }}>Web</span>
                    </Box>
                    <UserInfo />
                </Box>
            </Box>
        </>
    )
}
export default PortalHeader
